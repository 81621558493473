
import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/24/solid';
import {Link} from "react-router-dom";

import r4glogo from '../../res/img/R4G-logo.png';
import '../../styles/main.css';

import StepsNavigation from '../../shared_components/StepsNavigation';
import ACPTypeSelect from './components/ACPTypeSelect';
import {t} from "i18next";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import Input from '../eligibility/components/Input';
import DialogMessage from '../../shared_components/DialogMessage';
import Layout from '../../shared_components/Layout';

import { useNavigate } from 'react-router-dom';
import { setDocumentHead } from '../../utils/document';



export default function SelectProgram() {
  var saved_values = localStorage.submit_obj ? JSON.parse(base64_decode(localStorage.submit_obj)) : {};

  if(!(saved_values && saved_values["firstName"])) {
      window.location.href = '/eligibility';
  }
  
  const navigate = useNavigate();


  const [show_public_housing_zone, set_show_public_housing_zone] = useState(saved_values.eligibilityProgramCode && saved_values.eligibilityProgramCode === "E4" ? true : false);
  const [public_housing_code, set_public_housing_code] = useState(saved_values.publicHousingCode && saved_values.publicHousingCode ? saved_values.publicHousingCode : 0);
  const [open_dialog, set_open_dialog] = useState(false);
  const [open_dialog_1, set_open_dialog_1] = useState(false);
  const [open_dialog_2, set_open_dialog_2] = useState(false);
  const [show_school_lunch, set_show_school_lunch] = useState(saved_values.eligibilityProgramCode && saved_values.eligibilityProgramCode === "E50" ? true : false);
  const [school_name, set_school_name] = useState(saved_values.schoolName && saved_values.schoolName ? saved_values.schoolName : "");

  

  const choose_program = (e) => {
    localStorage.select_acp_done = false;
    saved_values["eligibilityProgramCode"] = e.value;
    localStorage.submit_obj = base64_encode(JSON.stringify(saved_values));
    if(e.value == "E4"){
      set_show_public_housing_zone(true);
    }else if(e.value == "E50"){
        set_show_school_lunch(true);
    }else{
      set_show_public_housing_zone(false);
      set_show_school_lunch(false);
    }
  }

  const handleCloseDialog_1 = () => {
    set_open_dialog_1(false);
  }

  const handleCloseDialog_2 = () => {
    set_open_dialog_2(false);
  }

  const go_to_next_page = () => {
    if(show_public_housing_zone && !public_housing_code){
      set_open_dialog(true);
      return;
    }

    if(show_school_lunch && !school_name){
      set_open_dialog_2(true);
      return;
    }

    if(show_school_lunch){
      saved_values["schoolName"] = school_name;
    }

    //if(!saved_values["publicHousingCode"]) saved_values["publicHousingCode"] = "E2";

    if(!saved_values["eligibilityProgramCode"]){
      set_open_dialog_1(true);
      return;
    }

    localStorage.submit_obj = base64_encode(JSON.stringify(saved_values));

    localStorage.select_acp_done = true;
    navigate("/confirm");
  }

  const handleChangeSelect = (e) => {
    localStorage.select_acp_done = false;
    console.log(e.target.name, e.target.value);
    set_public_housing_code(e.target.value);
    saved_values["publicHousingCode"] = e.target.value;
    localStorage.submit_obj = base64_encode(JSON.stringify(saved_values));
  }

  const handleCloseDialog = (action) => {
    set_open_dialog(action);
  }

  console.log(saved_values);

  setDocumentHead("Select program | Rural4G - Affordable Connectivity Program");

  return (
    <Layout title="Qualifying Programs">
      <div className='mx-auto max-w-7xl sm:px-6 lg:px-0 mb-80'>

              <DialogMessage buttons={[{label:"Ok"},{label:"Ok"}]} open={open_dialog} title="Please select your public housing type" description="For the Federal Public Housing Assistance, you are required to choose the public housing type you have." show_close_dialog={handleCloseDialog}/>
              <DialogMessage buttons={[{label:"Ok"},{label:"Ok"}]} open={open_dialog_2} title="Please enter your school name" description="For the School Lunch/Breakfast Program, you are required to enter your school name." show_close_dialog={handleCloseDialog_2}/>

              <div className='p-4 md:p-10  bg-slate-50'>
                <ACPTypeSelect selected={saved_values && saved_values["eligibilityProgramCode"] ? saved_values["eligibilityProgramCode"] : ""} handleSelected={choose_program} params={{label: "Select a program that you or someone in your household participate in:"}}/>
              </div>

              {show_public_housing_zone && (<div className='md:w-1/3 w-full'>
                <Input   
                    selected={public_housing_code}
                    params={{
                        label: "Public housing type",
                        type: "select",
                        name: "publicHousingCode",
                        placeholder: "Public housing type",
                        ariadescribedby: "state-description",
                        description:"",
                        options: [{
                          label:"Select housing choice",
                          value : ''
                        },{
                          label:"Housing Choice Voucher Program",
                          value : 1
                        },{
                          label:"Project-based rental assistance",
                          value : 2
                        },{
                          label:" Public housing",
                          value : 3
                        },{
                          label:" Affordable Housing Programs for American Indians, Alaska Natives or Native Hawaiians",
                          value : 4
                        }],
                        required : true
                    }} 
                    handleChange={handleChangeSelect}
                />
              </div> ) 
              }
              
              {
                show_school_lunch && (
                  <div className='md:w-1/3 w-full'>
                    <Input
                      params={{
                        label: "Enter Your School Name",
                        type: "text",
                        name: "schoolName",
                        placeholder: "School Name",
                        ariadescribedby: "state-description",
                        description:"",
                        value : school_name,
                        required : true
                      }}
                      handleChange={(e) => {
                        //saved_values["schoolName"] = e.target.value;
                        set_school_name(e.target.value)
                        localStorage.submit_obj = base64_encode(JSON.stringify(saved_values));
                      }}
                    />


                  </div>
                )
              }

              <DialogMessage 
                open={open_dialog_1} 
                title="Please select an eligibility program" 
                description="The program under which you are eligible for the Affordable Connectivity Program." 
                show_close_dialog={handleCloseDialog_1}
                buttons={[
                    {label:"Ok"}
                ]}
              />

              <div className="m-10">
                        <Link to="/eligibility">
                            <button type="button" className="border rounded-md bg-slate-800 text-white p-2 px-4">
                                Previous
                            </button>
                        </Link>
                        <button onClick={go_to_next_page} type="button" className="border rounded-md bg-rural-4g-2 text-white p-2 px-4">
                                Next
                        </button>
              </div>
            </div>
    </Layout>
  )
}
