import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    FingerPrintIcon,
    LockClosedIcon,
    DocumentIcon,
    ClockIcon
} from '@heroicons/react/24/outline';

import happy_news from '../../res/img/happy_news.svg'
import r4g_logo from '../../res/img/R4G-logo.png'
import { setDocumentHead } from '../../utils/document';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { verify_the_request_code } from '../../net/api';
import { useEffect, useState } from 'react';
import { Dot } from 'react-animated-dots';

export default function RegistrationCompleted() {
    setDocumentHead("Completed | Rural4G - Affordable Connectivity Program");
    //Get param from 
    const params = useParams();
    const navigate = useNavigate();
    const { verif_code } = params;
    const [checking_verification_code, set_checking_verification_code] = useState(true);
    const [found_valid_application, set_found_valid_application] = useState(false);

    const verify_the_code = async () => {
        try {
            set_checking_verification_code(true);
            const res = await verify_the_request_code(verif_code);
            set_checking_verification_code(false);
            console.log(res);
            if (res && res.application_found && res.app_id) {
                set_found_valid_application(true);
                localStorage.setItem("application_done", true);
                localStorage.setItem("all_conditions_signed", true);
                localStorage.setItem("app_id", res.app_id);
                localStorage.order_id_obj = JSON.stringify(res.order_id_obj);
                //navigate(`/order/plans`);
            }
        } catch (ex) {
            set_checking_verification_code(false);
            set_found_valid_application(false);
            // alert("Something went wrong, please try again later");
            // window.location.href = "";
        }

    }

    useEffect(() => {
        verify_the_code();
    }, []);

    return (
        <div className="bg-white py-4 sm:py-2">

            <div className="mx-auto max-w-7xl px-6 lg:px-8 py-4">

                <div className="mx-auto max-w-2xl text-center mt-4">

                    <div className='text-center mb-8'>
                        <img className='w-32 inline' src={r4g_logo} />
                    </div>

                    {
                        checking_verification_code && <div>
                            <div className='text-center text-2xl'>
                                Verifying your application, please wait <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot>
                            </div>
                        </div>
                    }

                    {
                        (!checking_verification_code && !found_valid_application) && <div>
                            <div className='text-center text-xl my-2'>
                                The was an error verifying your application, please try again later
                            </div>
                            <div className=' my-8'>
                                <a href="" className="bg-rural-4g-2 hover:bg-rural-4g-2 text-white py-2 px-4 rounded">
                                    Try Again
                                </a>
                            </div>

                        </div>
                    }

                    {!checking_verification_code && found_valid_application && <div>
                        <div className='text-center'>
                            <img className='w-40 inline' src={happy_news} alt="Waiting " />
                        </div>


                        <p className="mt-2 text-xl md:text-2xl text-center font-bold tracking-tight text-gray-900">
                            Thank you for your application for ACP Benefit
                        </p>

                        <div className="mx-auto mt-4 max-w-2xl sm:mt-2 lg:mt-2 lg:max-w-4xl text-center">
                            <Link to="/order/plans" className="bg-rural-4g-2 hover:bg-rural-4g-2 text-white py-2 px-4 rounded">
                                Proceed to Order Now
                            </Link>
                        </div>
                    </div>}

                </div>


                <div className='text-center absolute w-full bottom-8 left-0'>
                    <div>
                        If you have a problem, please contact us at <a className='underline' href="mailto:contact@rural4g.com">contact@rural4g.com</a>.
                    </div>
                </div>
            </div>


        </div>
    )
}
