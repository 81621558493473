import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    FingerPrintIcon,
    LockClosedIcon,
    DocumentIcon,
    ClockIcon
} from '@heroicons/react/24/outline';

import passing_by from '../../res/img/passing_by.svg'
import mindfulness from '../../res/img/mindfulness.svg'
import r4g_logo from '../../res/img/R4G-logo.png'
import { setDocumentHead } from '../../utils/document';

const features = [
    {
        name: 'Enroll For Your ACP Benefit',
        description:
            'Claim your Affordable Connectivity Program(ACP) benefit. Click the button below to start the application.',
        icon: DocumentIcon,
        button_text: 'Apply for ACP',
        href: '/coverage',
        bgColor: 'bg-rural-4g-2',
        bgHoverColor: 'bg-rural-4g-2'
    },
    {
        name: 'Sign Up For Internet Service',
        description:
            'Click the button below to choose an Internet plan and apply your benefit to that plan if you have an approved application ID.',
        icon: ClockIcon,
        button_text: 'Order Now',
        href: '/order',
        bgColor: 'bg-green-500',
        bgHoverColor: 'bg-green-700'
    },
]

const platform_okay = true;

export default function Start() {
    localStorage.removeItem("coverage_check_done");
    platform_okay ? setDocumentHead("Start | Rural4G - Affordable Connectivity Program") : setDocumentHead("Many requests | Rural4G - Affordable Connectivity Program")

    return (
        <div className="bg-white py-4 sm:py-2">
            <div className='text-center'>
                <img className='w-32 inline' src={r4g_logo} />
            </div>
            {!platform_okay ? <div className='text-center my-4 px-4'>
                <div ><img className='h-32 md:h-72 inline' src={mindfulness}/> </div>
                <div className='text-xl md:text-2xl my-4 font-bold text-gray-800'>Our Eligibility form is temporarily unavailable due to system maintenance.</div>
                <div className='text-md inline-block md:max-w-md leading-8 text-gray-600'>
                    Please use <a href="https://acpbenefit.org" className='underline'>the national Verifier Database</a> to confirm eligibility. 
                    Visit <a href="https://rural4g.com/acp-consent/" className='underline'>Rural4G ACP Consent</a> to request the monthly Internet discount.<br/>
                    We should be back online shortly.
                </div>
                <div className='my-2'>
                    {/* Submit your email address to be informed when the form is back.*/}
                    <a
                        href="https://rural4g.com/contact/"
                        target='_blank'
                        className={`inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-rural-4g-2 hover:bg-rural-4g-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-rural-4g-2`}
                        >
                        Contact us
                        </a>
                    
                    
                </div>
            </div> : <div className="mx-auto max-w-7xl px-6 lg:px-8 py-4">

                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600 hidden">ACP Program</h2>
                    <div className='text-center'>
                        <img className='w-60 inline' src={passing_by} alt="Waiting " />
                    </div>

                    <p className="mt-2 text-2xl md:text-4xl font-bold tracking-tight text-gray-900">
                        Apply for the Affordable Connectivity Program Benefit
                    </p>

                </div>
                <div className="mx-auto mt-4 max-w-2xl sm:mt-20 lg:mt-10 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-slate-400">
                                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                                <div className='my-2'>
                                    <a
                                        href={feature.href}
                                        className={`inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white ${feature.bgColor} hover:${feature.bgHoverColor} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:${feature.bg}`}
                                    >
                                        {feature.button_text}
                                    </a>
                                </div>
                            </div>
                        ))}
                    </dl>
                </div>

                <div className='text-center my-8'>
                    <div className="mt-6 text-lg inline-block w-80 lg:w-1/2 leading-8 text-gray-600">
                        It is easy to apply for the Affordable Connectivity Program through this online portal. You must provide information about yourself or the Benefit Qualifying Person.
                    </div>
                </div>
            </div>}
        </div>
    )
}
