
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/24/solid';
import {Link} from "react-router-dom";

import r4glogo from '../../res/img/R4G-logo.png';
import '../../styles/main.css';

import StepsNavigation from '../../shared_components/StepsNavigation';
import Form from './components/Form';

import {t} from "i18next";
import Layout from '../../shared_components/Layout';
import { setDocumentHead } from '../../utils/document';



export default function Elibility() {
  if(!(localStorage.coverage_check_done && localStorage.coverage_check_done === "true")) {
    window.location.href = '/coverage';
  }

  setDocumentHead("Check eligibility | Rural4G - Affordable Connectivity Program");

  return (
    <Layout title="Check your eligibility">
              <div className='p-4 md:p-10  bg-slate-50'>
                <Form/>
              </div>
    </Layout>
  );
}
