
import { Fragment, useState, useEffect } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { CheckIcon, DocumentTextIcon } from '@heroicons/react/24/solid'
import { Link } from "react-router-dom";
import { decode as base64_decode } from "base-64";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DataLine from './data_line';
import { acp_program_list } from '../selectProgram/components/ACPTypeSelect';
import { useNavigate } from 'react-router-dom';

import r4glogo from '../../res/img/R4G-logo.png';
import '../../styles/main.css';

import StepsNavigation from '../../shared_components/StepsNavigation';
import { post, send_error_log } from '../../net/api';
import Layout from '../../shared_components/Layout';
import { usa_states } from '../eligibility/components/usa_states';
import { setDocumentHead } from '../../utils/document';





export default function ConfirmElibility() {
  const [elig_check_id_class_name, set_elig_check_id_class_name] = useState("");
  const [elig_app_id_class_name, set_elig_app_id_class_name] = useState("");
  const [show_loading_check, set_show_loading_check] = useState(false);
  const [show_eligibility_fail, set_show_eligibility_fail] = useState(false);
  const [show_eligibility_success, set_show_eligibility_success] = useState(false);
  const [show_unable_to_connect, set_show_unable_to_connect] = useState(false);

  const [elig_check_id, set_eligibility_check_id] = useState("");
  const [elig_expiration_date, set_elig_expiration_date] = useState("");
  const [elig_failures, set_elig_failures] = useState([]);
  const [elig_application_id, set_elig_application_id] = useState("");
  const [elig_last_review_time, set_elig_last_review_time] = useState("");

  const [elig_errors, set_elig_errors] = useState([]);

  const [show_data_list, set_show_data_list] = useState(true);

  const [application_status, set_application_status] = useState("");
  const [success_urls, set_success_urls] = useState([]);
  const [elig_global_state, set_elig_global_state] = useState({
    "activeSubscriber": "",
    "applicationId": "",
    "eligibilityCheckId": "",
    "failures": [],
    "eligibilityExpirationDate": "",
    "status": "",
    "links": {},
    "_links": {},
    "lastReviewTime": "",
    "errorId": "",
    "errors": [],
    "message": "",
    "timestamp": ""
  });

  const navigate = useNavigate();

  const goToNextPage = () => {
    navigate('/final');
  }

  let form_ = {};

  const get_status_explanation = (status) => {
    switch (status) {
      case "PENDING_CERT":
        return `This status indicates the application is pending a consumer’s completed certifications. The consumer is determined to be eligible, but must complete all required certifications in the National Verifier via the URL provided in “links.”`;
      case "PENDING_REVIEW":
        return `This status indicates the application is undergoing a manual review by USAC for uploaded supporting documentation to confirm their Affordable Connectivity Program eligibility.`;
      case "PENDING_RESOLUTION":
        return `This status indicates the application is incomplete and requires the consumer to provide additional information/documents through the National Verifier.`;
      default:
        return "";
    }
  }

  const key_labels = {
    "salutation": "Salutation",
    "firstName": "First Name",
    "middleName": "Middle Name",
    "lastName": "Last Name",

    "address": "Residential Address",
    "state": "State",
    "city": "City",
    "zipCode": "Zip Code",
    "urbanizationCode": "Urbanization code", //good

    "dob": "Date of Birth",
    "dayDob": "Day of Birth",
    "monthDob": "Month of Birth",
    "yearDob": "Year of Birth",

    "ssn4": "Last 4 digits of your Social Security Number",
    "tribalId": "Tribal ID",

    "bqpFirstName": "Beneficiary First Name",
    "bqpLastName": "Beneficiary Last Name",
    "bqpDob": "Beneficiary Date of Birth",
    "bqpSsn4": "Last 4 Digits of Beneficiary Social Security Number",
    "bqpTribalId": "Beneficiary Tribal ID",

    "dayBqpDob": "Beneficiary Day of Birth",
    "monthBqpDob": "Beneficiary Month of Birth",
    "yearBqpDob": "Beneficiary Year of Birth",

    "alternateId": "Alternate ID",
    "bqpAlternateId": "Beneficiary Alternate ID",

    "eligibilityProgramCode": "Eligibility Program Code",
    "publicHousingCode": "Public Housing Code",
    "consentInd": "Consent ID",
    "contactPhoneNumber": "Contact Phone Number",
    "contactEmail": "Contact Email",
    "contactAddress": "Contact Address",
    "contactCity": "Contact City",
    "contactState": "Contact State",
    "contactZipCode": "Contact Zip Code",
    "contactUrbCode": "Contact Urbanization Code",

    "repId": "Representative ID",
    "repNotAssisted": "Representative not assisted",
    "carrierUrl": "Carrier URL",

    "otherAptFloor": "Other Apartment Floor",
    "otherAddress": "Other Address",
    "otherCity": "Other City",
    "otherState": "Other State",
    "otherStateShipping": "Other Shipping State",
    "otherZipCode": "Other Zip Code",
    "otherUrbCode": "Other urbanization Code",
    "wayToReach": "Best Way to Reach Out",
    "residenceType": "Residence Type",
    "aptFloor": "Apartment Floor",
    "schoolName": "School Name",
  };


  var counter_send_attemps = 0;
  const handleTextCopied = (state_function) => {
    state_function("font-bold");
    setTimeout(() => {
      state_function("");
    }, 100);
  }

  const submit_obj = localStorage && localStorage.submit_obj ? JSON.parse(base64_decode(localStorage.submit_obj)) : "";
  let counter = 0;

  if (!(submit_obj && submit_obj["firstName"])) {
    navigate('/');
  }

  const getFormFields = () => {
    const submit_form = JSON.parse(base64_decode(localStorage.submit_obj));
    const coverage_info = JSON.parse(localStorage.coverage_info);
    let coverage_for = coverage_info['coverage_for'];

    const key_labels = {
      "salutation": submit_form["salutation"],
      "firstName": submit_form["firstName"],
      "middleName": submit_form["middleName"],
      "lastName": submit_form["lastName"],

      "address": submit_form["address"],
      "state": submit_form["state"],
      "city": submit_form["city"],
      "zipCode": submit_form["zipCode"],
      "urbanizationCode": submit_form["urbanizationCode"], //good

      "yearDob": submit_form["yearDob"],
      "monDob": submit_form["monthDob"],
      "dayDob": submit_form["dayDob"],

      "dob": submit_form["yearDob"] + "-" + submit_form["monthDob"] + "-" + submit_form["dayDob"],
      "ssn4": submit_form["ssn4"],
      "tribalId": submit_form["tribalId"],

      "bqpFirstName": submit_form["bqpFirstName"],
      "bqpLastName": submit_form["bqpLastName"],

      "yearBqpDob": submit_form["yearBqpDob"],
      "monBqpDob": submit_form["monthBqpDob"],
      "dayBqpDob": submit_form["dayBqpDob"],

      "bqpDob": submit_form["yearBqpDob"] + "-" + submit_form["monthBqpDob"] + "-" + submit_form["dayBqpDob"],
      "bqpSsn4": submit_form["bqpSsn4"],
      "bqpTribalId": submit_form["bqpTribalId"],

      "alternateId": submit_form["alternateId"],
      "bqpAlternateId": submit_form["bqpAlternateId"],

      "eligibilityProgramCode": submit_form["eligibilityProgramCode"],
      "publicHousingCode": submit_form["publicHousingCode"],
      "consentInd": submit_form["consentInd"],

      "contactPhoneNumber": submit_form["contactPhoneNumber"],
      "contactEmail": submit_form["contactEmail"],
      "contactAddress": submit_form["contactAddress"],
      "contactCity": submit_form["contactCity"],
      "contactState": submit_form["contactState"],
      "contactZipCode": submit_form["contactZipCode"],
      "contactUrbCode": submit_form["contactUrbCode"],

      "otherAddress": submit_form["otherAddress"],
      "otherState": submit_form["otherState"],
      "otherCity": submit_form["otherCity"],
      "otherZipCode": submit_form["otherZipCode"],
      "otherUrbCode": submit_form["otherUrbCode"],

      "carrierName": localStorage.getItem("cellular_carrier_name") ? localStorage.getItem("cellular_carrier_name") : "",

      "repId": "",
      "repNotAssisted": "",
      "schoolName": submit_form["schoolName"],
      "carrierUrl": `${window.location.orgin}/order`,

      "coverage_for": coverage_for
    };

    return key_labels;
  }

  const handleSubmit = async () => {
    const obj = getFormFields();
    console.log(obj);
    set_show_loading_check(true);
    set_show_unable_to_connect(false);
    try {
      const response = await post("eligibility/check", obj);
      console.log(response);
      set_show_loading_check(false);


      if (
        response.status === "PENDING_RESOLUTION" ||
        response.status === "PENDING_CERT" ||
        response.status === "IN_PROGRESS" ||
        response.status === "PENDING_REVIEW" ||
        response.status === "COMPLETE"
      ) {
        localStorage.verification_done = 'true';
      } else if (response.status === "BAD_REQUEST") {
        localStorage.errors = JSON.stringify(response.errors);
        navigate("/eligibility");
      } else {
        alert(response.message);
        set_show_unable_to_connect(true);
        return;
      }
      set_elig_global_state(response);
      if (window.location.hostname === "localhost") {
      } else {
        setTimeout(() => {
          localStorage.removeItem("submit_obj");
          localStorage.removeItem("personal_info_done");
          localStorage.removeItem("select_acp_done");
          localStorage.removeItem("verification_done");
        }, 3000);
      }

      if (response.status === "COMPLETE") {
        // navigate("/thank-you");
        
        const order_id_obj = {
          "first_name": obj["firstName"],
          "last_name": obj["lastName"],
          "contact_phone": obj["contactPhoneNumber"],
          "contact_email": obj["contactEmail"],
          "year_of_birth": obj["yearDob"],
          "month_of_birth": obj["monDob"],
          "day_of_birth": obj["dayDob"],
          "city": obj["contactCity"],
          "state": obj["state"],
          "zip_code": obj["contactZipCode"],
          "street_address": obj["contactAddress"],
          "address_line_2": "-",
          "ssn4": obj["ssn4"]
        };


        localStorage.setItem("application_done", true);
        localStorage.setItem("app_id", response.applicationId);
        localStorage.setItem("all_conditions_signed", true);
        localStorage.setItem("order_id_obj", JSON.stringify(order_id_obj));
      }
    } catch (ex) {
      send_error_log({ err: ex, data: obj });
      set_show_loading_check(false);
      set_show_unable_to_connect(true);
      console.log("Error", ex);
    }
  }

  const not_to_be_displayed = ["alternateId", "bqpAlternateId", "publicHousingCode", "consentInd", "repId", "repNotAssisted", "carrierUrl", "useAnotherShippingAddress"];

  setDocumentHead("Submit your application | Rural4G - Affordable Connectivity Program");

  return (
    <Layout title="Submit Your Application">
      <div className='mx-auto max-w-7xl sm:px-6 lg:px-0 mb-80'>

        <div className='p-10 pt-2 bg-slate-50'>

          {!show_loading_check && !(elig_global_state.status) && !(show_unable_to_connect) && (<div className="border-t border-gray-200">
            <div className="text-xl my-4 text-center">Please review the following information before you submit</div>
            <dl>
              {Object.keys(submit_obj).map((key, i) => submit_obj[key] && !not_to_be_displayed.includes(key) && <DataLine elt_name={key_labels[key]} elt_value={key === "eligibilityProgramCode" ? acp_program_list.map((acp_program) => {
                if (submit_obj[key] === acp_program.value) {
                  return acp_program.title;
                }
              }) : key === "state" || key === "otherState" || key === "otherStateShipping" ? usa_states.map((state) => state.abbreviation == submit_obj[key] ? state.name : null) : key == "salutation" ? submit_obj[key].charAt(0).toUpperCase() + submit_obj[key].slice(1) : submit_obj[key]} type={counter++ % 2 === 0 ? "pair" : "impair"} />)}
            </dl>
            <button onClick={handleSubmit} className="px-4 py-2 bg-sky-500 text-white rounded-md">Submit Application</button>
          </div>)
          }

          {show_loading_check && <div className='text-center'>
            <svg className="animate-spin -ml-1 mr-3 h-16 w-16 inline text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <div className="text-xl mt-2">We are checking your eligibility, please wait ...</div>
          </div>}

          {!show_loading_check && (elig_global_state.activeSubscriber) && (<div className={false ? 'hidden' : ''}>
            <div style={{ textAlign: "center" }} className='center m-10 mt-2 mb-4'>
              {elig_global_state.status === "COMPLETE" ? <div className='inline-block w-20 h-20 bg-green-200 rounded-full pt-5 pl-5'>
                <CheckIcon className="h-10 w-10 text-green" aria-hidden="true" />
              </div> : <div className='inline-block w-20 h-20 bg-sky-200 rounded-full pt-5 pl-5'>
                <DocumentTextIcon className="h-10 w-10 text-slate-600" aria-hidden="true" />
              </div>}
              {elig_global_state.status === "COMPLETE" ? <div>
                <div className='text-xl font-bold pb-2'>Your application is complete</div>
              </div> : <div>
                <div className='text-xl font-bold pb-4'>We need more information</div>
              </div>}
              {elig_global_state._links && Object.keys(elig_global_state._links).map((key, index) => {
                if (elig_global_state._links[key]) return (
                  <div key={index}>
                    <div>
                      <a className="border rounded-md bg-rural-4g-2 text-white p-2 px-4 " href={elig_global_state._links[key].href} target="_blank" rel="noreferrer">Go to {key} URL</a>
                    </div>
                  </div>
                );
              })
              }

              {elig_global_state.status && elig_global_state.status.toUpperCase() === "COMPLETE" && <div className='mt-2'>
                <Link to={`/order/plans`}>
                  <button type="button" className="border rounded-md bg-green-600 text-white p-2 px-4">
                    Proceed to the order section
                  </button>
                </Link>
              </div>}
            </div>

            <div className='text-center'>
              <div>
                <div><small>Eligibility check ID</small></div>
                <div>
                  <span className={elig_check_id_class_name}>{elig_global_state.eligibilityCheckId}</span>
                  <CopyToClipboard text={elig_global_state.eligibilityCheckId} onCopy={(e) => handleTextCopied(set_elig_check_id_class_name)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline ml-2 text-gray-400 hover:text-gray-600 mouse-pointer">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                    </svg>
                  </CopyToClipboard>
                </div>
              </div>

              <div>
                <div><small>Application ID</small></div>
                <div>
                  <span className={elig_app_id_class_name}>{elig_global_state.applicationId}</span>
                  <CopyToClipboard text={elig_global_state.applicationId} onCopy={(e) => handleTextCopied(set_elig_app_id_class_name)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline ml-2 text-gray-400 hover:text-gray-600 mouse-pointer">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                    </svg>
                  </CopyToClipboard>
                </div>
              </div>



              {elig_global_state.eligibilityExpirationDate && <div>
                <div><small>Eligibility expiration date</small></div>
                <div>
                  {elig_global_state.eligibilityExpirationDate}
                </div>
              </div>}

              {elig_global_state.lastManualReviewTime && <div>
                <div><small>Last review time</small></div>
                <div>{elig_global_state.lastManualReviewTime}</div>
              </div>}

              {elig_global_state.activeSubscriber && <div>
                <div><small>Active subscriber</small></div>
                <div>{elig_global_state.activeSubscriber === "N" ? "No" : "Yes"}</div>
              </div>}

              {elig_global_state.tribalStatusConfirmed && <div>
                <div><small>Tribal status confirmed</small></div>
                <div>{elig_global_state.tribalStatusConfirmed === "N" ? "No" : "Yes"}</div>
              </div>}

              <div>
                <div><small>Application status</small></div>
                <div>{elig_global_state.status}</div>

                <div>
                  <div className='w-72 inline-block my-4'>
                    <small>{get_status_explanation(elig_global_state.status)}</small>
                  </div>

                </div>
              </div>





            </div>
          </div>)
          }

          {!show_loading_check && (elig_global_state.errorId) && <div className={false ? 'hidden' : ''}>
            <div style={{ textAlign: "center" }} className='center m-10'>
              <div className='inline-block w-20 h-20 bg-red-200 rounded-full pt-5 pl-5'>

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-10 h-10">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>

              </div>
              <div className='text-xl'>Verification did not go through</div>
              <div className='text-l mt-4'>Please fix the following errors :</div>
              {elig_global_state.errors && elig_global_state.errors.length && elig_global_state.errors.map(error => { return (<div className='px-4 py-2 bg-red-100 rounded my-2 border'>{error.description}</div>) }
              )}
              <div className='py-2'>
                <Link to="/eligibily" className="px-4 py-2 bg-indigo-500 text-white rounded-md">Fix the errors</Link>
              </div>
            </div>
          </div>
          }

          {(show_unable_to_connect) && <div className={false ? 'hidden' : ''}>
            <div style={{ textAlign: "center" }} className='center m-10'>
              <div className='inline-block w-20 h-20 bg-red-200 rounded-full pt-5 pl-5'>

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-10 h-10">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>

              </div>
              <div className='text-xl my-2'>There was an error reaching the server, please try again</div>

              <div className='my-2'><button onClick={handleSubmit} className="px-4 py-2 bg-sky-500 text-white rounded-md">Try again</button></div>

            </div>
          </div>
          }
        </div>

        <div className="mt-10 hidden">
          <Link to="/verif">
            <button type="button" className="border rounded-md bg-slate-800 text-white p-2 px-4">
              Previous
            </button>
          </Link>
          <Link to="/final">
            <button type="button" className="border rounded-md bg-rural-4g-2 text-white p-2 px-4">
              Next
            </button>
          </Link>
        </div>
      </div>

    </Layout>
  )
}
