import OrderLayout from "./components/OrderLayout";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { saveApplicationConsent } from "../../net/api";
import { Dot } from "react-animated-dots";
import { setDocumentHead } from "../../utils/document";


export default function Conditions(props) {
    const navigate = useNavigate();
    let customer_names = "";
    let customer_initials = "";

    if(!(localStorage && localStorage.getItem("application_done"))){
       navigate("/order");
    }

    const saved_obj = localStorage.order_id_obj ? JSON.parse(localStorage.order_id_obj) : {};

    if(saved_obj && saved_obj.first_name && saved_obj.last_name){
        customer_names = saved_obj.first_name + " " + saved_obj.last_name;
        customer_initials = saved_obj.first_name.toUpperCase().charAt(0) + saved_obj.last_name.toUpperCase().charAt(0);
    }else{
        navigate("/order");
    }

    const [submitting, setSubmitting] = useState(false);

    const [conditions, setConditions] = useState([
        {
            id: 1,
            text: 'ACP PROVIDES A DISCOUNT OF UP TO $30 PER MONTH DEPENDING ON THE PRICE OF THE RURAL4G SERVICE TIER OR BUNDLE SELECTED. IF THE TOTAL BILL EXCEEDS $30 PER MONTH, I WILL BE RESPONSIBLE FOR THE REMAINING BALANCE AFTER THE ACP DISCOUNT IS APPLIED. THIS INCLUDES ANY TAXES AND REQUIRED FEES THAT ARE APPLIED TO THE FULL AMOUNT OF THE SERVICE.',
            signature: '',
            signed : false
        },
        {
            id: 2,
            text: 'WHEN THE AFFORDABLE CONNECTIVITY PROGRAM (ACP) ENDS AND I OPT TO KEEP MY BROADBAND SERVICE PLAN, THE RATES RETURN TO STANDARD NON-DISCOUNTED RATES.',
            signature: '',
            signed : false
        },
        {
            id: 3,
            text: 'I MAY ONLY RECIEVE ONE ACP BENEFIT PER HOUSEHOLD, FROM ONE PARTICIPATING PROVIDER, AND I CERTIFY THAT NO OTHER MEMBER OF MY HOUSEHOLD IS RECEIVING AN EMERGENCY BROADBAND BENEFIT UNDER THE ACP PROGRAM',
            signature: '',
            signed : false
        },
        {
            id: 4,
            text: 'ALL OFFICIAL COMMUNICATIONS FOR ACP WILL BE VIA ELECTRONIC MAIL OR TEXST MESSAGING OR PHONE CALL AND I CONSENT TO RECEIVE SUCH COMMUNICATIONS FROM RURAL4G.',
            signature: '',
            signed : false
        }
    ]);

    const [same_signature, set_same_signature] = useState({
        same: false,
        bg_color : 'bg-green-100'
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        //Check all conditions are signed
        for(var i = 0; i < conditions.length; i++){
            if(conditions[i].signed === false){
                alert("Please sign all conditions");
                return;
            }
        }

        if(same_signature.same === false){
            alert("Please sign all conditions with the same signature");
            return;
        }

        //Check all checkboxes are checked
        for(var i = 0; i < checkboxes.length; i++){
            if(checkboxes[i].checked === false){
                alert("Please check all checkboxes");
                return;
            }
        }

        localStorage.all_conditions_signed = true;
        // console.log('submit');
        //navigate("/order/plans");
        let order_obj_id = localStorage.getItem("order_id_obj");
        order_obj_id = order_obj_id ? JSON.parse(order_obj_id) : null;
        order_obj_id.app_id = localStorage.getItem("appId");

        setSubmitting(true);
        saveApplicationConsent(order_obj_id).then((res) => {
            console.log(res);
            if(res.success === true){
                localStorage.setItem("application_done", true);
                navigate("/order/plans");
            }
            setSubmitting(false);
        }).catch((err) => {
            setSubmitting(false);
        });
    }

    const handleSignatureChange = (e, index) => {  
        const newConditions = [...conditions];
        newConditions[index].signature = e.target.value;
        newConditions[index].signed = e.target.value.length > 0;
        setConditions(newConditions);
        //check all filled signatures are the same 
        
    }

    const checkSignatureSame = (e, index) => {
        const newConditions = [...conditions];
        let last_signature = customer_initials;
        for(var i = 0; i < newConditions.length; i++){
            if(newConditions[i].signature.length > 0){
                if(last_signature.length > 0 && last_signature !== newConditions[i].signature){
                    set_same_signature({
                        ...same_signature,
                        same: false,
                        bg_color : 'bg-red-100'
                    });
                    return;
                }
                last_signature = newConditions[i].signature;
            }
        }
        
        set_same_signature({
            ...same_signature,
            same: true,
            bg_color : 'bg-green-100'
        });
    }

    const [checkboxes, setCheckboxes] = useState([
        {
            name : "consent",
            checked : false
        },{
            name : "terms",
            checked : false
        }
    ]);

    setDocumentHead("Consent form | Rural4G - Affordable Connectivity Program");

    return (
        <OrderLayout title="Consent form">

            <form onSubmit={handleSubmit}>
                <div className="text-left bg-sky-100 p-2 rounded-md shadow">
                    <div className="text-center">
                        <div className="text-xl font-bold my-2 hidden">AFFORDABLE CONNECTIVITY PROGRAM CONSENT FORM</div>
                        <div className="lg:w-1/2 mx-auto text-slate-600 text-md my-2">By signing this form, I give my affirmative consent that I want to participate in the Affordable Connectivity Program through Rural4G and that I understand and certify that:</div>
                        
                    </div>

                    <div>
                        <ul role="list" className="divide-y divide-gray-200">
                            {conditions.map((condition, index) => (
                                <li
                                    key={condition.id}
                                    className={condition.signed === true ? `relative ${same_signature.bg_color} py-5 px-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50` : `relative bg-white py-5 px-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50`}
                                >

                                    <div className="mt-1">
                                        <p className="text-sm text-gray-600 line-clamp-2">{condition.text}</p>
                                    </div>
                                    <div className="my-2">
                                        <input
                                            type="text"
                                            className="rounded-md border border-slate-300"
                                            placeholder="Enter your initial"
                                            value={condition.signature}
                                            onChange={(e) => handleSignatureChange(e, index)}
                                            onBlur={(e) => checkSignatureSame(e, index)}
                                        />
                                    </div>
                                    <div className="text-italic text-slate-500 text-xs">If your name is {customer_names}, your initial will is {customer_initials}</div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="my-2 bg-white p-2">
                        <div className="relative flex items-start my-2">
                            <div className="flex h-5 items-center">
                                <input
                                    id="consent"
                                    aria-describedby="consent-description"
                                    name="consent"
                                    type="checkbox"
                                    checked={checkboxes[0].checked}
                                    onChange={(e) => {
                                        const newCheckboxes = [...checkboxes];
                                        newCheckboxes[0].checked = e.target.checked;
                                        setCheckboxes(newCheckboxes);
                                    }}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                            </div>
                            <div className="ml-3 text-sm">
                                <label htmlFor="consent" className="font-medium text-gray-700">
                                    CONSENT
                                </label>
                                <p id="consent-description" className="text-gray-500">
                                    I consent to all fields/questions that are presented above.
                                </p>
                            </div>
                        </div>
                        <div className="relative flex items-start">
                            <div className="flex h-5 items-center">
                                <input
                                    id="terms"
                                    aria-describedby="terms-description"
                                    name="terms"
                                    type="checkbox"
                                    checked={checkboxes[1].checked}
                                    onChange={(e) => {
                                        const newCheckboxes = [...checkboxes];
                                        newCheckboxes[1].checked = e.target.checked;
                                        setCheckboxes(newCheckboxes);
                                    }}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                            </div>
                            <div className="ml-3 text-sm">
                                <label htmlFor="terms" className="font-medium text-gray-700">
                                    TERMS OF SERVICE AGREEMENT
                                </label>
                                <p id="terms-description" className="text-gray-500">
                                    I CONSENT to ALL Terms of Services agreement(s) that are associated with the Affordable Connectivity Program
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="my-2 bg-white p-2">
                        <button type="submit" className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">
                            {submitting ? <span>Submit <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span> : `Submit`}
                        </button>
                    </div>
                </div>
            </form>
        </OrderLayout>
    );
}