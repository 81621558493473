import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'

const mailingLists = [
  { id: 1, title: 'Yes', description: '', users: 'Select', status : 'yes' },
  { id: 2, title: 'No', description: '', users: 'Select', status : 'no' },
  { id: 3, title: 'I am not sure', description: '', users: 'Select', status : 'idk' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NetworkCoverageCheck(props) {
  const [selectedMailingLists, setSelectedMailingLists] = useState(null);

  const handleSelectNetwork = (status) => {
    props.set_state_fn({
        ...props.state,
        have_cellular_coverage : status,
        zip_covered : status,
        cellular_carrier_name : "",
        zip_can_be_covered : false
    });
  }

  return (
    <RadioGroup value={selectedMailingLists} onChange={setSelectedMailingLists}>
      <RadioGroup.Label className="text-base font-medium text-gray-900">Do you have a cellular network at your home?</RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        {mailingLists.map((mailingList) => (
          <RadioGroup.Option
            key={mailingList.id}
            value={mailingList}
            onClick={() => handleSelectNetwork(mailingList.status)}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-rural-4g-2 ring-2 ring-rural-4g-2' : '',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {mailingList.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                      {mailingList.description}
                    </RadioGroup.Description>
                    <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                      <div className='inline border px-4 py-2 rounded-md bg-sky-200'>{mailingList.users}</div>
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-rural-4g-2')}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-rural-4g-2' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
