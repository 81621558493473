import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    FingerPrintIcon,
    LockClosedIcon,
    DocumentIcon,
    ClockIcon
} from '@heroicons/react/24/outline';

import passing_by from '../../res/img/passing_by.svg'
import mindfulness from '../../res/img/mindfulness.svg'
import r4g_logo from '../../res/img/R4G-logo.png'
import { setDocumentHead } from '../../utils/document';
import { useState } from 'react';
import { submit_email_for_notification } from '../../net/api';



const platform_okay = false;

export default function Pause() {
    localStorage.removeItem("coverage_check_done");
    platform_okay ? setDocumentHead("Start | Rural4G - Affordable Connectivity Program") : setDocumentHead("Many requests | Rural4G - Affordable Connectivity Program")

    const [email, setEmail] = useState('');
    const [submitting_email, setSubmittingEmail] = useState(false);
    const handle_submit_email = async (e) => {
        e.preventDefault();

        if(!email){
            alert('Please enter your email address');
            return;
        }

        // if(window.confirm('Are you sure you want to submit your email address?')){
            try{
                setSubmittingEmail(true);
                const res = await submit_email_for_notification({email});
                setSubmittingEmail(false);
                if(res.status === 'success'){
                    alert('Your email address has been submitted successfully. We will notify you when the form is back.');
                    setEmail('');
                }

            }catch(e){
                setSubmittingEmail(false);
                alert('An error occurred while submitting your email address. Please try again later.');
            }
        // }
    }

    return (
        <div className="bg-white py-4 sm:py-2 max-w-5xl mx-auto">
            <div className='text-center'>
                <img className='w-32 inline' src={r4g_logo} alt="Rural4G logo" />
            </div>
            {!platform_okay ? <div className='text-center my-4 px-4'>
                <div ><img className='h-32 md:h-40 inline' src={mindfulness} alt="Mindfulness lady" /> </div>
                <div className=' my-4 font-bold text-gray-600 max-w-md mx-auto'>
                    Per the Federal Communications Commission, new applications for the Affordable Connectivity Program are not being accepted after February 7, 2024.
                </div>
                <div className='text-md inline-block md:max-w-md leading-8 text-gray-600'>
                    <a href="https://www.fcc.gov/acp" className='underline'>Click here to read official statement by FCC</a>. <br />
                    <div className='text-xl font-bold my-5'>Visit <a href="https://rural4g.com/plans/" className='underline'>our plans</a> page to see our current offerings.</div>
                </div>

                {/* Create a simple form to subscribe to be informed when ACP is back */}
                <div className='my-[40px] bg-slate-100 py-10 px-2'>
                    <div className='text-md my-4 font-bold text-gray-800 '>
                        Sign up to be kept up-to-date on the affordable connectivity program
                    </div>
                    <form className='mt-4 max-w-md mx-auto' onSubmit={handle_submit_email}>
                        <div className='lg:flex'>
                            <input type='email' onChange={(e) => setEmail(e.target.value)} className='rounded-md w-full border border-gray-300 lg:rounded-r-none lg:rounded-l-md p-2' placeholder='Your email address' />
                            <button type='submit' className='my-2 w-full lg:w-auto lg:my-0 rounded-md bg-rural-4g-2 text-white lg:rounded-r-md lg:rounded-l-none p-2'>
                                {submitting_email ? 'Submitting ...' : 'Submit'}
                            </button>
                        </div>
                    </form>
                </div>

                <div className='my-10'>
                    {/* Submit your email address to be informed when the form is back.*/}
                    <a
                        href="https://rural4g.com/contact/"
                        target='_blank'
                        className={`underline text-rural-4g-2 hover:text-rural-4g-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rural-4g-2`}
                    >
                        Contact us for more information
                    </a>


                </div>
            </div> : <div className="mx-auto max-w-7xl px-6 lg:px-8 py-4">

                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600 hidden">ACP Program</h2>
                    <div className='text-center'>
                        <img className='w-60 inline' src={passing_by} alt="Waiting " />
                    </div>

                    <p className="mt-2 text-2xl md:text-4xl font-bold tracking-tight text-gray-900">
                        Apply for the Affordable Connectivity Program Benefit
                    </p>

                </div>


                <div className='text-center my-8'>
                    <div className="mt-6 text-lg inline-block w-80 lg:w-1/2 leading-8 text-gray-600">
                        It is easy to apply for the Affordable Connectivity Program through this online portal. You must provide information about yourself or the Benefit Qualifying Person.
                    </div>
                </div>
            </div>}
        </div>
    )
}
