import Layout from "../../shared_components/Layout";
import SearchZone from "./components/SearchZone";
import { useState } from "react";
import { get_application_status } from "../../net/api";
import { Link } from "react-router-dom";
import { setDocumentHead } from "../../utils/document";

export function StatusCheck() {
    const url_params = new URLSearchParams(window.location.search);
    const app_id = url_params.get("id");

    const [eligibility_check, set_eligibility_check] = useState(app_id ? app_id : "");
    const [application_id, set_application_id] = useState("");
    const [status, set_status] = useState("");
    const [eligibility_expiration_date, set_eligibility_expiration_date] = useState("");
    const [resolution, set_resolution] = useState("");
    const [subscriber_status, set_subscriber_status] = useState("");

    const [show_loading_check_data, set_show_loading_check_data] = useState(false);
    const [show_status_info, set_show_status_info] = useState(false);
    const [failures, set_failures] = useState([]);

    const [show_status_error, set_show_status_error] = useState(false);
    const [status_error_msg, set_status_error_msg] = useState("");
    const [errors_list, set_errors_list] = useState([]);

    const handleChange = (e) => {
        set_eligibility_check(e.target.value);
    }

    const get_check_button = () => {
        if (window.location.pathname === "/status-check") {
            return (
                <div className='md:flex-auto my-4 text-right'>
                    <div>Haven't applied yet?
                        <Link to="/"><button className='ml-2 bg-green-500 text-white px-4 py-2 rounded-md'>Check your eligibility</button></Link>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='md:flex-auto my-4 text-right'>
                    <div>Applied before?
                        <Link to="/status-check"><button className='ml-2 bg-green-500 text-white px-4 py-2 rounded-md'>Check your application status </button></Link>
                    </div>
                </div>
            );
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // eligibility_check
        set_show_loading_check_data(true);
        set_show_status_info(false);
        set_show_status_error(false);
        get_application_status(eligibility_check)
            .then(data => {
                set_show_loading_check_data(false);
                console.log(data);
                if (data.status === "BAD_REQUEST") {
                    set_status_error_msg(data.message);
                    set_show_status_error(true);
                    console.log("Bad request", data.errors);
                    set_errors_list(data.errors);

                } else {
                    set_show_status_info(true);
                    data.activeSubscriber === "Y" ? set_subscriber_status("Active") : set_subscriber_status("Inactive");
                    set_application_id(data.applicationId);
                    set_status(data.status);
                    set_eligibility_expiration_date(data.eligibilityExpirationDate);
                    if (data._links && data._links.resolution) set_resolution(data._links.resolution.href);
                    if (data.failures) set_failures(data.failures);
                }
            })
            .catch(error => {
                set_show_loading_check_data(false);
                set_show_status_info(false);
                console.log("Error ", error);
            });
    }

    setDocumentHead("Status check | Rural4G - Affordable Connectivity Program");

    return (
        <Layout hide_steps={true} title="Status check">
            <div className="py-0 pb-4 px-5 md:hidden">
                {get_check_button()}
                <div className="text-center text-2xl pt-4 font-bold">Check your application status</div>
            </div>

            <div>
                <SearchZone value={eligibility_check} handleChange={handleChange} handleSubmit={handleSubmit} />
            </div>

            {show_loading_check_data && <div className="text-center bg-slate-50 mb-4 my-2 p-4 rounded-md">
                <svg class="animate-spin -ml-1 mr-3 h-6 w-6 inline text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <div className=" mt-2">We are checking your status, please wait ...</div>
            </div>}

            {show_status_error && <div className="text-center bg-red-50 rounded-md py-4 px-4">
                <div className="text-red-600">{status_error_msg}</div>
                {
                    errors_list.map((error, index) => {
                        return <div className="text-slate-800" key={index}>{error.description}</div>
                    }
                    )
                }
            </div>

            }

            {show_status_info && <div className="text-center">
                <div>
                    <div className="inline-block">
                        <div><small>Application ID</small></div>
                        <div>
                            <span>{application_id}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="inline-block">
                        <div><small>Status</small></div>
                        <div>
                            <span>{status}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="inline-block">
                        <div><small>Subscriber status</small></div>
                        <div>
                            <span>{subscriber_status}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="inline-block">
                        <div><small>Eligibility expiration date</small></div>
                        <div>
                            <span>{eligibility_expiration_date}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="inline-block">
                        <div><small>Failures</small></div>
                        <div>
                            <ul>
                                {failures.map((failure, index) => {
                                    return <li key={index}>{failure}</li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                {status && status.toUpperCase() !== "COMPLETE"  && (<div>
                    <div className="inline-block">
                        <div><small>Resolution</small></div>
                        <div className="py-4">
                            <span><a className="px-4 py-2 bg-rural-4g-2 text-white rounded-md" href={resolution}>Click here to find resolution</a></span>
                        </div>
                    </div>
                </div>)}

                <div className='mt-6'>
                      {status && status.toUpperCase() === "COMPLETE" && <Link to={`/order?id=${eligibility_check}`}>
                          <button type="button" className="border font-bold rounded-md bg-green-600 text-white p-2 px-4">
                              Proceed to the order section
                          </button>
                      </Link>}
                </div>

            </div>}
        </Layout>
    );
}