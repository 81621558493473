import { CheckIcon } from '@heroicons/react/24/solid';

import {Link} from "react-router-dom";

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import r4glogo from '../res/img/R4G-logo.png';

let steps = [
  { id: '01', name: 'Check Coverage', href : localStorage.coverage_check_done && localStorage.coverage_check_done === 'true' ? '/coverage' : '', href1: '/coverage', status: 'upcoming' },
  { id: '02', name: 'Personal Info', href : localStorage.personal_info_done && localStorage.personal_info_done === 'true' ? '/eligibility' : '', href1: '', status: 'current'}, //complete
  { id: '03', name: 'Qualifying Programs', href : localStorage.select_acp_done && localStorage.select_acp_done === 'true' ? '/select-program' : '', href1: '/select-program', status: 'upcoming'},
  { id: '04', name: 'Verification', href : localStorage.verification_done && localStorage.verification_done === 'true'  ? '/confirm' :'', href1: '/confirm', status: 'upcoming'},
  //{ id: '04', name: 'Final step', href : localStorage.final_step_done && localStorage.final_step_done === 'true' ? '/final' : '', href1: '/final', status: 'upcoming' },
]


export default function StepsNavigation() {
  const get_check_button = () => {
      if(window.location.pathname === "/status-check"){
          return (
              <div className='md:flex-auto my-4 text-right'>
                          <div>Haven't applied yet? 
                          <Link to="/coverage"><button className='ml-2 bg-green-500 text-white px-4 py-2 rounded-md'>Check your eligibility</button></Link>
                          </div>
                  </div>
          );
      }else {
          return (
              <div className='md:flex-auto my-4 text-right'>
                          <div>Applied before? 
                          <Link to="/status-check"><button className='ml-2 bg-green-500 text-white px-4 py-2 rounded-md'>Check your application status </button></Link>
                          </div>
                  </div>
          );
      }
  }

  console.log(window.location.pathname);

  steps[1].status = localStorage.personal_info_done && localStorage.personal_info_done === 'true' ? 'complete' : 'upcoming';
  steps[2].status = localStorage.select_acp_done && localStorage.select_acp_done === 'true' ? 'complete' : 'upcoming';
  steps[3].status = localStorage.verification_done && localStorage.verification_done === 'true'  ? 'complete' : 'upcoming';
  steps[0].status = localStorage.coverage_check_done && localStorage.coverage_check_done === 'true'  ? 'complete' : 'upcoming';
  //steps[3].status = localStorage.final_step_done && localStorage.final_step_done === 'true' ? 'complete' : 'upcoming';

  switch(window.location.pathname){
    case '/eligibility':
      steps[1].status = steps[1].status === 'complete' ? steps[1].status : 'current';
      break;
    case '/select-program':
      steps[2].status = steps[2].status === 'complete' ? steps[2].status : 'current';
      break;
    case '/confirm':
      steps[3].status = steps[3].status === 'complete' ? steps[3].status : 'current';
      break;
    /*case '/final':
      steps[3].status = steps[3].status === 'complete' ? steps[3].status : 'current';
      break;*/
    case '/coverage':
      steps[0].status = steps[0].status === 'complete' ? steps[0].status : 'current';
      break;
  }

  return (
    <>
      <Popover className="relative bg-white">
      <div className="pointer-events-none absolute inset-0 z-30 shadow" aria-hidden="true" />
      <div className="relative z-20">
        <div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-5 sm:px-6 sm:py-4 md:justify-start md:space-x-10 lg:px-8">
            <div className='sm:show md:hidden'>
              <a href="https://rural4g.com"><img src={r4glogo} className="h-10 my-5" alt="Rural 4G logo"/></a>
            </div>
            <div className="-my-2 -mr-2 md:hidden">
          {/*<div className="-my-2 -mr-2 md:hidden">*/}
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rural-4g-2">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition md:hidden"
        >
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6 sm:pb-8">
              <div className="flex items-center justify-between">
                <div>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rural-4g-2">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6 sm:mt-8">
                <nav>
                  <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                  {steps.map((step, stepIdx) => (
                    step.status === 'complete' ? (
                      <Link to={step.href} className="group flex w-full items-center">
                        <span className="flex items-center px-6 py-1 text-sm font-medium">
                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-rural-4g-2 group-hover:bg-rural-4g-2">
                            <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                          <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                        </span>
                      </Link>
                    ) : step.status === 'current' ? (
                      <a href={step.href} className="flex items-center px-6 py-1 text-sm font-medium" aria-current="step">
                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-rural-4g-2">
                          <span className="text-rural-4g-2">{step.id}</span>
                        </span>
                        <span className="ml-4 text-sm font-medium text-rural-4g-2">{step.name}</span>
                      </a>
                    ) : (
                      <Link to={step.href} className="group flex items-center">
                        <span className="flex items-center px-6 py-1 text-sm font-medium">
                          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                            <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                          </span>
                          <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                        </span>
                      </Link>
                    )
                  )
                )}


                  {/*
                    {steps.map((item) => (
                      <a
                        
                        href={item.href}
                        className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
                      >
                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                            <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                        <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                      </a>
                    ))} */}
                  </div>
                  
                </nav>
              </div>
            </div>
            <div className="py-0 pb-4 px-5">
              {get_check_button()}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>


    <nav aria-label="Progress" className='hidden md:block'>
      <ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === 'complete' ? (
              <Link to={step.href} className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-rural-4g-2 group-hover:bg-rural-4g-2">
                    <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                </span>
              </Link>
            ) : step.status === 'current' ? (
              <a href={step.href} className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-rural-4g-2">
                  <span className="text-rural-4g-2">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-rural-4g-2">{step.name}</span>
              </a>
            ) : (
              <Link to={step.href} className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                </span>
              </Link>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
    </>
  )
}
