export default function Checkbox(props){
    return (
        <fieldset className="space-y-5">
            <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                <input
                    onChange={(e) => {console.log("Click");props.handleChange(e)}}
                    aria-describedby={props.params.ariadescribedby}
                    name={props.params.name}
                    type="checkbox"
                    required={props.params.required}
                    checked={props.params.checked}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                </div>
                <div className="ml-3 text-sm">
                    <label htmlFor={props.params.ariadescribedby} className="font-medium text-gray-700">
                        {props.params.label}
                    </label>
                </div>
            </div>
            
        </fieldset>
    );
}