import { BarsArrowUpIcon, MagnifyingGlassIcon, FingerPrintIcon } from '@heroicons/react/20/solid';

function SearchZone1() {
  return (
    <div className="mx-2 my-6 w-72">
      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
        Your application check ID
      </label>
      <div className="mt-1">
        <input
          type="text"
          name="status-check-id"
          id="status-check-id"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="XXXX-XXXX-XXXX-XXXX"
          aria-describedby="status-check-description"
        />
      </div>
      <p className="mt-2 text-sm text-gray-500" id="status-check-description">
        We'll only use this for spam.
      </p>
    </div>
  )
}




export default function SearchZone(props) {
  return (
    <div className=''>
      <div className='mt-6 inline p-2 text-center'>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
          Eligibility check ID
        </label>
        <form onSubmit={props.handleSubmit} className='text-center'>
          <div className="mt-1 w-96 inline-block rounded-md shadow-sm ">
            <div className='flex'>
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <input
                  type="text"
                  name="status-check-id"
                  id="status-check-id"
                  className="block w-full p-4 text-l rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 "
                  placeholder="XXXXXXXXXXXXXXXX"
                  aria-describedby="status-check-description"
                  value={props.value}
                  onChange={props.handleChange}
                />
              </div>
              <button
                type="submit"
                className="relative -ml-px bg-rural-4g-2 text-white inline-flex items-center space-x-2 rounded-r-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-rural-4g-2 "
              >
                <MagnifyingGlassIcon className="h-5 w-5 text-white font-bold" aria-hidden="true" />
                <span className='text-white font-bold'>Check</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
