import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';


import home_router from "../../../res/img/device_new.png";
import tablet_device from "../../../res/img/tablet.png";

import { useState } from 'react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  const mailingLists = [
    { id: 1, type : "no", title: 'No', description: 'Choose no if you do not live in tribal areas and you did not qualify as tribal.', users: 'Select' },
    { id: 2, type : "yes", title: 'Yes', description: 'Select "yes" if you are living in tribal areas and if you qualified as tribal.', users: 'Select' }
  ] 

export default function DiscountType(props) {
    const [selectedMailingLists, setSelectedMailingLists] = useState();
  
    const handleSelect = (type) => {
        if(props.fn_state_2 && typeof props.fn_state_2 === 'function'){
            props.fn_state_2(type);
        }else{
            props.fn_state(type);
        }
      
    }
  
    return (
      <RadioGroup value={selectedMailingLists} onChange={setSelectedMailingLists}>
        <RadioGroup.Label className="text-base font-medium text-gray-900">Are you applying for <b>tribal</b> discount? </RadioGroup.Label>
        
        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          {mailingLists.map((mailingList) => (
            <RadioGroup.Option
              key={mailingList.id}
              value={mailingList}
              onClick={() => handleSelect(mailingList.type)}
              className={({ checked, active }) =>
                classNames(
                  checked ? 'border-transparent' : 'border-gray-300',
                  active ? 'border-rural-4g-2 ring-2 ring-rural-4g-2' : '',
                  'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      
                      <RadioGroup.Label as="span" className="block text-xl font-bold font-medium text-gray-900">
                        {mailingList.title}
                      </RadioGroup.Label>
                      <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                        {mailingList.description}
                      </RadioGroup.Description>
                      <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                        <span className="border px-2 py-2 rounded-md">{mailingList.users}</span>
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <CheckCircleIcon
                    className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-rural-4g-2')}
                    aria-hidden="true"
                  />
                  <span
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-rural-4g-2' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
        <div className='text-sm my-4 bg-gray-100 text-gray-600 p-2 rounded-md'>A verification will be performed after we receive the application to make sure you qualify.</div>
      </RadioGroup>
    )
  }