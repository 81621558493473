export const put_saved_obj_in_zoho_checkout_url = (zoho_checkout_url) => {
    const saved_obj = localStorage.order_id_obj && localStorage.order_id_obj !== "undefined" ? JSON.parse(localStorage.order_id_obj) : {};
    
    const saved_obj_keys = Object.keys(saved_obj);
    if (saved_obj_keys.length > 0) {
      let params = '';
      if(saved_obj.first_name) params += `&first_name=${saved_obj.first_name}`;
      if(saved_obj.last_name) params += `&last_name=${saved_obj.last_name}`;
      if(saved_obj.contact_email) params += `&email=${saved_obj.contact_email}`;
      if(saved_obj.contact_phone) params += `&mobile=${saved_obj.contact_phone}`;
      if(saved_obj.street_address)  params += `&billing_street=${saved_obj.street_address}`;
      if(saved_obj.city) params += `&billing_city=${saved_obj.city}`;
      if(saved_obj.state) params += `&billing_state_code=${saved_obj.state}`;
      if(saved_obj.zip_code) params += `&billing_zip=${saved_obj.zip_code}`;
      if(saved_obj.street_address) params += `&shipping_street=${saved_obj.street_address}`;
      if(saved_obj.city) params += `&shipping_city=${saved_obj.city}`;
      if(saved_obj.state) params += `&shipping_state_code=${saved_obj.state}`;
      if(saved_obj.zip_code) params += `&shipping_zip=${saved_obj.zip_code}`;
      params += `&billing_country_code=US`;
      params += `&shipping_country_code=US`;
  
      return `${zoho_checkout_url}${params}`;
    } else {
      return zoho_checkout_url;
    }
}
