//import {handleChange} from "./Form";
import { CheckIcon } from "@heroicons/react/20/solid";
import {decode as base64_decode, encode as base64_encode} from "base-64";

export default function Input(props){

    const _usac_errors = localStorage.errors && JSON.parse(localStorage.errors);
    const check_usac_error = (name) => {
        
        for(var i in _usac_errors){
            const name_ = name && name.toLowerCase();
            //console.log(_usac_errors[i].field.toLowerCase(), name.toString().toLowerCase());
            if(_usac_errors[i].field.toLowerCase() === name_){
                return [true, _usac_errors[i].description];
            }
        }
        return false;
    }

    const getInputField = (params) => {
        //console.log(params.display);

        const submit_obj = localStorage && localStorage.submit_obj ? JSON.parse(base64_decode(localStorage.submit_obj)) : null;
        ///console.log(submit_obj);
        const error = check_usac_error(props.params.name);
        //console.log(error);
        const error_class_text = error ? "text-red-600 border-red-400 bg-red-500 block w-full rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm" : "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";
        const error_class_email = error ? "text-red-600 block w-full rounded-md border-red-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm" : "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";
        const error_class_tel = error ? "text-red-600 block w-full rounded-r-md border-red-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm" : "block w-full rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";
        const error_class_select = error ? "text-red-600 relative block w-full rounded-md border-red-300 bg-transparent focus:z-10 focus:border-red-500 focus:ring-indigo-500 sm:text-sm" : "relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";
        const error_class_date = error ? "text-red-600 block w-full rounded-md border-red-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm" : "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";
        const error_class_radio = error ? "h-4 w-4 border-red-300 text-red-600 focus:ring-red-500":"h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500";
        //console.log(error_class_text);

        switch(params.type){
            case "text":
                
                return <input
                            type={props.params.type}
                            key={props.Key}
                            name={props.params.name}
                            maxLength={props.params.maxLength}
                            minLength={props.params.minLength}
                            className={error_class_text}
                            placeholder={props.params.value ? props.params.value : props.params.placeholder}
                            aria-describedby={props.params.ariadescribedby}
                            onChange={(e) => {
                                props.handleChange(e)}}
                            required={props.params.required}
                            value={props.params.value }
                            
                        />;
            case "email":
                            return <input
                                        type={props.params.type}
                                        key={props.Key}
                                        name={props.params.name}
                                        maxLength={props.params.maxLength}
                                        minLength={props.params.minLength}
                                        className={error_class_email}
                                        placeholder={props.params.placeholder}
                                        aria-describedby={props.params.ariadescribedby}
                                        onChange={(e) => props.handleChange(e)}
                                        required={props.params.required}
                                        value={props.params.value }
                                    />;
            case "tel":
                                        return <div className="flex"><div className="flex pt-2 pl-4 rounded-l-md pr-1 bg-slate-200">+1</div><div className="flex-auto"><input
                                                    type={props.params.type}
                                                    key={props.Key}
                                                    name={props.params.name}
                                                    maxLength={props.params.maxLength}
                                                    minLength={props.params.minLength}
                                                    className={error_class_tel}
                                                    placeholder={props.params.placeholder}
                                                    aria-describedby={props.params.ariadescribedby}
                                                    onChange={(e) => props.handleChange(e)}
                                                    required={props.params.required}
                                                    value={props.params.value }
                                                /></div></div>;
            case "select":
                
                return <select required={props.params.required} onChange={(e) => props.handleChange(e)} type="select" name={params.name} className={error_class_select}>
                        {params.options && params.options.map((option, index) => {
                            return <option disabled={option.disabled} selected={props.selected === option.value ? true : false} value={option.value}>{option.label}</option>
                            //return <option value=""></option>;//<option value={option.value} disabled={option.value === "" ? true : false}>{option.label}</option>
                        })}
                </select>;
            case "date":
                return <input
                    type={props.params.type}
                    name={props.params.name}
                    onChange={(e) => props.handleChange(e)}
                    className={error_class_date}
                    placeholder={props.params.placeholder}
                    aria-describedby={props.params.ariadescribedby}
                />;
            case "radio":
                    return <fieldset className="mt-4">
                            <legend className="sr-only">Notification method</legend>
                            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            {props.params.options.map((option) => (
                                <div key={option.id} className="flex items-center">
                                <input
                                    id={option.id}
                                    required={props.params.required}
                                    onChange={(e) => props.handleChange(e)}
                                    name={option.name}
                                    value={option.value}
                                    type="radio"
                                    checked={props.selected === option.value ? true : false}
                                    defaultChecked={option.id === 'email'}
                                    className={error_class_radio}
                                />
                                <label htmlFor={option.id} className="ml-3 block text-sm font-medium text-gray-700">
                                    {option.title}
                                </label>
                                </div>
                            ))}
                            </div>
                        </fieldset>;
           
            default:
                return "text";
        }
    }

    const classNameElt = props.params.display === false ? "flex-auto md:flex-1 my-2 mx-2 hidden" : "flex-auto md:flex-1 my-2 mx-2";
    const error = check_usac_error(props.params.name);

    return (
        <div className={classNameElt}>
            <label htmlFor={props.params.ariadescribedby} className="block text-sm font-medium text-gray-700">
                {props.params.label} {props.params.required ? <span className="text-red-500">*</span> : null}
            </label>
            <div className="mt-1">
                {getInputField(props.params)}
            </div>
            <p className="mt-2 text-sm text-gray-500">
                <i>{props.params.description}</i>
                {props.valid && props.valid.toLowerCase() === "y" ? <CheckIcon className="w-4 h-4 text-green-600"/> : ''}
                {props.valid && props.valid.toLowerCase() === "n" ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-red-500">
                    <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
                </svg> : ''}
                {
                    error && <div><small>{error[1]}</small></div>
                }
            </p>
        </div>
    );
}