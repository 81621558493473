import Layout from "../../shared_components/Layout";

import Form from "../eligibility/components/Form";
import Input from "../eligibility/components/Input";
import DatePicker from "../eligibility/components/DatePicker";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { useEffect, useState } from "react";
import { usa_states } from "../eligibility/components/usa_states";
import Tablet from "./components/Tablet";
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { checkAppIdExists, check_application_identity } from "../../net/api";
import { Dot } from "react-animated-dots";
import CheckAppId from "./components/CheckAppId";
import { Link } from "react-router-dom";
import Conditions from "./Conditions";
import OrderLayout from "./components/OrderLayout";
import { days, months, years } from "../eligibility/components/input_structure";
import { useNavigate } from "react-router-dom";
import { setDocumentHead } from "../../utils/document";

let form_vals = {
    "appId": ""
}

let has_tried_loading = false;

export default function Order(props) {
    const url_params = new URLSearchParams(window.location.search);
    const app_id = url_params.get("id");

    const [application_check, set_application_check] = useState({
        loading: false,
        resp_obj: {},
        app_id: app_id ? app_id : ""
    });

    if (app_id) {
        form_vals.appId = app_id;
    }

    const navigate = useNavigate();

    const saved_obj = localStorage.order_id_obj ? JSON.parse(localStorage.order_id_obj) : {};

    //Get the application id from the url


    const [global_state, set_global_state] = useState({
        "loading": false,
        "form": {
            "first_name": "",
            "last_name": "",
            "contact_phone": "",
            "contact_email": "",
            "year_of_birth": "",
            "month_of_birth": "",
            "day_of_birth": "",
            "city": "",
            "state": "",
            "zip_code": "",
            "street_address": "",
            "address_line_2": "-",
            "ssn4": ""
        }
    });

    const handleFormChange = (e) => {
        set_global_state({
            ...global_state,
            form: {
                ...global_state.form,
                [e.target.name]: e.target.selectedOptions ? e.target.selectedOptions[0].value : e.target.value
            }
        });
    }

    const handleContinue = (e) => {
        e.preventDefault();

        let form = global_state.form;
        let first_empty_field = "";
        for (let key in form) {
            if (!form[key]) {
                first_empty_field = key;
                break;
            }
        }

        if (application_check && application_check.resp_obj && application_check.resp_obj.application_found === true) {
            localStorage["application_done"] = true;
            localStorage["all_conditions_signed"] = false;
            navigate("/order/plans");
            return;
        }

        if (first_empty_field && application_check.resp_obj && application_check.resp_obj.application_found === false) {
            document.getElementsByName(first_empty_field)[0].focus();
        } else {
            localStorage.application_done = 1;
            localStorage.order_id_obj = JSON.stringify(global_state.form);
            navigate("/order/conditions");
        }

        console.log(global_state.form);

    }



    function handleChangeInput(evt) {
        form_vals = {
            ...form_vals,
            [evt.target.name]: evt.target.value
        }

        set_application_check({
            ...application_check,
            app_id: evt.target.value
        });

        console.log(form_vals);
    }

    async function handleCheckApplicationID(e) {
        if (e) e.preventDefault();
        has_tried_loading = true;

        localStorage.removeItem("application_done");
        localStorage.removeItem("all_conditions_signed");

        console.log(form_vals);
        if (form_vals.appId) {

            console.log(form_vals);

            set_application_check({
                ...application_check,
                loading: true
            });
            checkAppIdExists(form_vals.appId)
                .then((resp) => {
                    set_application_check({
                        ...application_check,
                        loading: false,
                        resp_obj: resp
                    });

                    if (resp && localStorage.appId && localStorage.appId === form_vals.appId) {
                        set_global_state({
                            ...global_state,
                            form: {
                                "first_name": saved_obj && saved_obj.first_name ? saved_obj.first_name : "",
                                "last_name": saved_obj && saved_obj.last_name ? saved_obj.last_name : "",
                                "contact_phone": saved_obj && saved_obj.contact_phone ? saved_obj.contact_phone : "",
                                "contact_email": saved_obj && saved_obj.contact_email ? saved_obj.contact_email : "",
                                "year_of_birth": saved_obj && saved_obj.year_of_birth ? saved_obj.year_of_birth : "",
                                "month_of_birth": saved_obj && saved_obj.month_of_birth ? saved_obj.month_of_birth : "",
                                "day_of_birth": saved_obj && saved_obj.day_of_birth ? saved_obj.day_of_birth : "",
                                "city": saved_obj && saved_obj.city ? saved_obj.city : "",
                                "state": saved_obj && saved_obj.state ? saved_obj.state : "",
                                "zip_code": saved_obj && saved_obj.zip_code ? saved_obj.zip_code : "",
                                "street_address": saved_obj && saved_obj.street_address ? saved_obj.street_address : "",
                                "address_line_2": saved_obj && saved_obj.address_line_2 ? saved_obj.address_line_2 : "",
                                "ssn4": saved_obj && saved_obj.ssn4 ? saved_obj.ssn4 : ""
                            }
                        });
                    } else {
                        localStorage.removeItem("order_id_obj");
                    }

                    localStorage.appId = form_vals.appId;
                    console.log(resp);
                    localStorage.tribal = resp.tribal_status ? resp.tribal_status : "";
                    console.log(resp);
                })
                .catch((err) => {
                    set_application_check({
                        ...application_check,
                        loading: false
                    });
                    console.log(err);
                });
        }
    }

    useEffect(() => {


    }, [global_state.form]);

    setTimeout(() => {
        if (app_id && !has_tried_loading) {
            handleCheckApplicationID();
        }
    }, 1500);

    console.log(application_check);

    setDocumentHead("Submit your order | Rural4G - Affordable Connectivity Program");

    const [application_verif, set_application_verif] = useState({
        month_of_birth : "",
        day_of_birth : "",
        year_of_birth : ""
    });
    const [verifying_identity, set_verifying_identity] = useState(false);

    const [identity_found, set_identity_found] = useState(false);

    const handleVerifyUser = async (e) => {
        e.preventDefault();
        //alert("Tabassage" + JSON.stringify(application_verif));
        set_identity_found(false);
        set_verifying_identity(true);
        const resp = await check_application_identity({...application_verif,app_id : localStorage.appId});
        set_verifying_identity(false);
        if(resp.application_found){
            set_identity_found(true);
            set_application_check({
                ...application_check,
                loading: false,
                resp_obj: resp
            });
        }else{
            set_identity_found(false);
            alert("We could not verify your identity. Please try again.");
        }
    }

    const handleChangeDateVerification = (e) => {
        set_application_verif({
            ...application_verif,
            [e.target.name] : e.target.value
        });
    }

    return (
        <>
            <OrderLayout title="Submit your order">
                <div className="w-full mt-8 text-slate-500">
                    Please enter your ACP Application ID to get started.
                </div>
                <div className="w-full text-center">
                    <CheckAppId
                        handleCheckApplicationID={handleCheckApplicationID}
                        handleChangeInput={handleChangeInput}
                        loading_application_id={application_check.loading}
                        app_id={application_check.app_id}
                    />
                </div>

                {application_check.resp_obj && application_check.resp_obj && application_check.resp_obj.application_found && application_check.resp_obj.status !== "COMPLETE" && application_check.resp_obj.href && <div className="mt-8 lg:w-1/2 mx-auto text-left">
                    <form onSubmit={handleVerifyUser}>
                    <div className="text-center font-bold max-w-lg mx-auto">You need to fix some issues on your application, we need your date of birth to verify it's you.</div>
                    <div className="w-full lg:flex">
                        <div className="flex-auto my-2 mx-2 w-full">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Date of birth  ? <span className="text-red-500">*</span>
                            </label>
                            <div className="flex">
                                <div className="mt-1  flex-auto">
                                    <select required="true" value={application_verif.month_of_birth} onChange={handleChangeDateVerification} type="select" name="month_of_birth" className="relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                        <option disabled="true"></option>
                                        {months && months.map((month, index) => {
                                            //console.log(month);
                                            return <option Key={index} value={month.value}>{month.label}</option>
                                            //return <option selected={parseInt(props.month) === parseInt(month.value) ? true : false } Key={index} value={month.value}>{month.label}</option>
                                        })
                                        }
                                    </select>
                                </div>
                                <div className="mt-1 mx-2 flex-auto">
                                    <select required="true" value={application_verif.day_of_birth} onChange={handleChangeDateVerification} type="select" name="day_of_birth" className="relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                        <option disabled="true"></option>
                                        {days && days.map((day, index) => {
                                            return <option Key={index} value={day}>{day}</option>
                                            //return <option selected={parseInt(props.day) === parseInt(day) ? true : false } Key={index} value={day}>{day}</option>
                                        })
                                        }
                                    </select>
                                </div>
                                <div className="mt-1 mx-2 flex-auto">
                                    <select required="true" value={application_verif.year_of_birth} onChange={handleChangeDateVerification} type="select" name="year_of_birth" className="relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                        <option disabled="true"></option>
                                        {years && years.map((year, index) => {
                                            return <option Key={index} value={year}>{year}</option>;
                                        })
                                        }
                                    </select>
                                </div>
                            </div>

                            
                        </div>
                        {/* <div className="flex-auto">
                            <Input className="flex-auto" params={{
                                label: "Last 4 Digits SSN",
                                type: "text",
                                name: "ssn4",
                                placeholder: "Enter SSN4",
                                ariadescribedby: "ssn4",
                                description: "",
                                maxLength: 4,
                                minLength: 4,
                                required: true,
                                value: global_state.form.ssn4
                            }} handleChange={handleFormChange} />
                        </div> */}
                    </div>
                    <div className="flex items-center justify-center my-2">
                        <button
                            type="submit"
                            disabled={verifying_identity}
                            className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            {verifying_identity ? 'Verifying...' : 'Verify my identity'}
                        </button>
                    </div>
                    </form>
                </div>}

                <div>
                    {application_check.resp_obj && application_check.resp_obj && application_check.resp_obj.application_found && application_check.resp_obj.status === "COMPLETE" && <div className="text-green-600">
                        Your application was found, you can proceed to purchase.
                    </div>}

                    {application_check.resp_obj && application_check.resp_obj && application_check.resp_obj.application_found && application_check.resp_obj.status !== "COMPLETE" && application_check.resp_obj.href && identity_found && <div className="text-orange-800">
                        Sorry, your application is not complete, you need to provide more information.
                        <div className="my-3 mx-2">
                            <a target="_blank" rel="noreferrer" href={application_check.resp_obj.href}>
                                <button type="button" className="bg-orange-300 px-4 py-2 text-white rounded-md">Click here to fix your application</button>
                            </a>
                        </div>
                    </div>
                    }

                    {application_check.resp_obj && application_check.resp_obj && application_check.resp_obj.application_found && application_check.resp_obj.status !== "COMPLETE" && !application_check.resp_obj.href && <div className="text-orange-800">
                        Sorry, your application has expired or was closed, please restart the application.
                        <div className="my-3 mx-2">
                            <a href='/coverage'>
                                <button type="button" className="bg-green-500 px-4 py-2 text-white rounded-md">Restart Application</button>
                            </a>
                        </div>
                    </div>
                    }

                </div>

                <form onSubmit={handleContinue}>

                    {(1 && (application_check.resp_obj && application_check.resp_obj.application_found === false)) && <div className="mt-8 lg:w-1/2 mx-auto text-left">

                        <div className="mb-8 mt-4 shadow border p-2 rounded-md">
                            <div className="sm:align-center sm:flex sm:flex-col">
                                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-center">Your identity</h1>
                            </div>
                            <div className="">
                                <Input className="flex-auto" params={{
                                    label: "First name",
                                    type: "text",
                                    name: "first_name",
                                    placeholder: "Enter your first name",
                                    ariadescribedby: "firstName",
                                    description: "",
                                    maxLength: 100,
                                    minLength: 2,
                                    required: true,
                                    value: global_state.form.first_name
                                }} handleChange={handleFormChange} />

                                <Input className="flex-auto" params={{
                                    label: "Last name",
                                    type: "text",
                                    name: "last_name",
                                    placeholder: "Enter your last name",
                                    ariadescribedby: "lastName",
                                    description: "",
                                    maxLength: 100,
                                    minLength: 2,
                                    required: true,
                                    value: global_state.form.last_name
                                }} handleChange={handleFormChange} />
                            </div>
                            <div className="w-full lg:flex">
                                <div className="flex-auto my-2 mx-2 w-full">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Date of birth  ? <span className="text-red-500">*</span>
                                    </label>
                                    <div className="flex">
                                        <div className="mt-1  flex-auto">
                                            <select required="true" value={global_state.form.month_of_birth} onChange={handleFormChange} type="select" name="month_of_birth" className="relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                                <option disabled="true"></option>
                                                {months && months.map((month, index) => {
                                                    //console.log(month);
                                                    return <option Key={index} value={month.value}>{month.label}</option>
                                                    //return <option selected={parseInt(props.month) === parseInt(month.value) ? true : false } Key={index} value={month.value}>{month.label}</option>
                                                })
                                                }
                                            </select>
                                        </div>
                                        <div className="mt-1 mx-2 flex-auto">
                                            <select required="true" value={global_state.form.day_of_birth} onChange={handleFormChange} type="select" name="day_of_birth" className="relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                                <option disabled="true"></option>
                                                {days && days.map((day, index) => {
                                                    return <option Key={index} value={day}>{day}</option>
                                                    //return <option selected={parseInt(props.day) === parseInt(day) ? true : false } Key={index} value={day}>{day}</option>
                                                })
                                                }
                                            </select>
                                        </div>
                                        <div className="mt-1 mx-2 flex-auto">
                                            <select required="true" value={global_state.form.year_of_birth} onChange={handleFormChange} type="select" name="year_of_birth" className="relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                                <option disabled="true"></option>
                                                {years && years.map((year, index) => {
                                                    return <option Key={index} value={year}>{year}</option>;
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <p className="mt-2 text-sm text-gray-500" id="email-description">
                                        Enter description
                                    </p>
                                </div>
                                <div className="flex-auto">
                                    <Input className="flex-auto" params={{
                                        label: "Last 4 Digits SSN",
                                        type: "text",
                                        name: "ssn4",
                                        placeholder: "Enter SSN4",
                                        ariadescribedby: "ssn4",
                                        description: "",
                                        maxLength: 4,
                                        minLength: 4,
                                        required: true,
                                        value: global_state.form.ssn4
                                    }} handleChange={handleFormChange} />
                                </div>
                            </div>
                            <div>
                                <div className="flex-auto">
                                    <Input handleChange={handleFormChange} className="flex-auto" params={{
                                        label: "Contact Phone Number",
                                        type: "tel",
                                        name: "contact_phone",
                                        placeholder: "XXXXXXXXXX",
                                        ariadescribedby: "contact-phone-description",
                                        description: "",
                                        required: true,
                                        value: global_state.form.contact_phone
                                    }} />
                                </div>
                            </div>
                            <div>
                                <Input valid={""} handleChange={handleFormChange} className="flex-auto" params={{
                                    label: "Contact Email Address",
                                    type: "email",
                                    name: "contact_email",
                                    placeholder: "Contact email address",
                                    ariadescribedby: "contact-email-description",
                                    description: "",
                                    required: true,
                                    value: global_state.form.contact_email
                                }} />
                            </div>
                        </div>

                        <div className="mb-8 mt-4  shadow border p-2 rounded-md">
                            <div className="sm:align-center sm:flex sm:flex-col mb-3">
                                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-center">Your address</h1>
                            </div>


                            <div className="md:flex bg-slate-100">
                                <Input
                                    valid={global_state.form.city}
                                    params={{
                                        label: "City",
                                        type: "text",
                                        name: "city",
                                        placeholder: "City",
                                        ariadescribedby: "city-description",
                                        description: "",
                                        value: global_state.form.city,
                                        required: true
                                    }}
                                    handleChange={handleFormChange}
                                />

                                <Input
                                    selected={global_state.form.state}
                                    params={{
                                        label: "State",
                                        type: "select",
                                        name: "state",
                                        placeholder: "State",
                                        ariadescribedby: "state-description",
                                        description: "",
                                        options: usa_states.map((state) => {
                                            return {
                                                label: state.name,
                                                value: state.abbreviation
                                            };
                                        }),
                                        required: true
                                    }}
                                    handleChange={handleFormChange}
                                />


                                <Input valid={global_state.form.zip_code} handleChange={handleFormChange} className="flex-auto" params={{
                                    label: "Zip Code",
                                    type: "text",
                                    name: "zip_code",
                                    placeholder: "Zip Code",
                                    ariadescribedby: "zip-code-description",
                                    description: "",
                                    display: global_state.form.zip_code,
                                    value: global_state.form.zip_code,
                                    required: true
                                }} />
                            </div>

                            <div>
                                <Input handleChange={handleFormChange} className="flex-auto" params={{
                                    label: "Street Address",
                                    type: "text",
                                    name: "street_address",
                                    placeholder: "Street Address",
                                    ariadescribedby: "residence-address-description",
                                    description: "",
                                    required: true,
                                    value: global_state.form.street_address
                                }} />
                            </div>

                            {/* <div>
                                    <Input handleChange={handleFormChange} className="flex-auto" params={{
                                        label: "Address line 2",
                                        type: "text",
                                        name: "address_line_2",
                                        placeholder: "Address line 2",
                                        ariadescribedby: "residence-address-description",
                                        description: "",
                                        required: false,
                                        value: global_state.form.address_line_2
                                    }} />
                                </div> */}
                        </div>
                    </div>}

                    {(1 && (application_check.resp_obj && application_check.resp_obj.status === "COMPLETE") || (application_check.resp_obj && application_check.resp_obj.application_found === false)) && <div className="lg:w-1/2 mx-auto">
                        <div className="mb-8 mt-4 p-2 rounded-md">

                            <button type="submit" className="bg-indigo-600 hover:bg-indigo-500 px-4 py-2 text-white rounded-md">Continue</button>

                        </div>
                    </div>
                    }

                </form>

            </OrderLayout>
        </>
    );
}


/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
function CheckApplicationID() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl py-12 px-6 lg:py-16 lg:px-8">
                <h2 className="inline text-3xl font-bold tracking-tight text-gray-900 sm:block sm:text-4xl">
                    Want product news and updates?
                </h2>
                <p className="inline text-3xl font-bold tracking-tight text-indigo-600 sm:block sm:text-4xl">
                    Sign up for our newsletter.
                </p>

            </div>
        </div>
    )
}






