import Layout from "../../shared_components/Layout";
import CarrierList from "./components/CarrierList";
import EnterZipCode from "./components/EnterZipCode";
import NetworkCoverageCheck from "./components/NetworkCoverageCheck";

import {useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import NotAvailable from "./components/NotAvailable";
import TabletOrHomeRouter from "./components/TabletOrHomeRouter";
import DialogMessage from "../../shared_components/DialogMessage";
import CheckTabletOrHome from "../order/components/CheckTabletOrHome";
import { setDocumentHead } from "../../utils/document";

export default function CheckCoverage(){
    const [global_state, set_global_state] = useState({
        zip_code: "",
        zip_code_error: false,
        zip_code_error_message: "",
        zip_code_valid: false,
        zip_code_valid_message: "",
        zip_code_validated: false,
        coverage_check_done: false,
        have_cellular_coverage : '',
        cellular_carrier_name : "",
        zip_covered : 'idk',
        coverage_for : '',
        zip_can_be_covered : false,
        type : ''
    });

    const [open_dialog, set_open_dialog] = useState(false);

    const navigate = useNavigate();



    const go_to_next_page = () => {
        localStorage.coverage_check_done = true;
        if(global_state.cellular_carrier_name){
            localStorage.cellular_carrier_name = global_state.cellular_carrier_name;
        }else{
            localStorage.removeItem("cellular_carrier_name");
        }
        localStorage.coverage_info = JSON.stringify(global_state);
        navigate("/eligibility");
    }

    const handleCloseDialog = () => {
        set_open_dialog(false);
    }
    
    const handleRestartApplication = () => {
        localStorage.clear();
        window.location.reload();
        set_open_dialog(false);
    }

    const handleClickRestartApplication = () => {
        set_open_dialog(true);
    }

    setDocumentHead("Check coverage | Rural4G - Affordable Connectivity Program");

    return (
        <Layout title="Check coverage">
              <div className='p-4 md:p-10  bg-slate-50'>
                <div className="lg:w-1/2 mx-auto">
                    <div className="mb-8 mt-4 shadow border p-2 rounded-md text-center">
                        <CheckTabletOrHome state={global_state} fn_state_2={set_global_state}/>
                    </div>
                </div>

                {global_state.coverage_for === "home" ? <div>
                    <NetworkCoverageCheck state={global_state} set_state_fn={set_global_state}/>
                    {global_state.have_cellular_coverage === 'yes' ? ( <CarrierList state={global_state} set_state_fn={set_global_state}/>
                    ) : global_state.have_cellular_coverage === 'idk' ? 
                        <>
                             <EnterZipCode state={global_state} set_state_fn={set_global_state}/>
                        </> :
                    global_state.have_cellular_coverage === 'no' ?  <div>
                        <NotAvailable/>    
                    </div> : ''}
                
                    
                </div> : global_state.coverage_for === "tablet" ? <div></div> : ''}

                {global_state.cellular_carrier_name || global_state.zip_can_be_covered ? <div className="m-10 text-center">
                        
                        <button type="button" onClick={handleClickRestartApplication} className="border rounded-md bg-red-500 text-white p-2 px-4">
                            Restart Application
                        </button>
                        <button onClick={go_to_next_page} type="button" className="border rounded-md bg-rural-4g-2 text-white p-2 px-4">
                            Next
                        </button>
                    </div> : ''}

              </div>

              <DialogMessage 
                open={open_dialog} 
                type="confirm"
                title="Are you sure you want to restart application?" 
                description="You will lose all the data you have entered so far." 
                show_close_dialog={handleCloseDialog}
                handleConfirm={handleRestartApplication}
                buttons={[
                    {label:"Cancel"},
                    {label:"Restart application"}
                ]}
            />

              
        </Layout>
    );
}