import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid';

export const acp_program_list = [
  { id: 1, value: "E2", title: 'Supplemental Nutrition Assistance Program', description: 'SNAP/Food Stamps/Food Assistance', users: '621 users' },
  { id: 2, value: "E1", title: 'Medicaid', description: '', users: '1200 users' },
  { id: 3, value: "E3", title: 'Supplemental Security Income(SSI)', description: '', users: '2740 users' },
  { id: 4, value: "E15", title: 'Veterans or Survivors Pension', description: '', users: '2740 users' },
  
  { id: 5, value: "E4", title: 'Federal Public Housing Assistance', description: '', users: '2740 users' },
  { id: 6, value: "E8", title: 'Bureau of Indian Affairs General Assistance', description: '', users: '2740 users' },
  { id: 7, value: "E9", title: 'Tribal Temporary Assistance for Needy Families (Tribal TANF)', description: '', users: '2740 users' },
  { id: 8, value: "E10", title: 'Food Distribution Program on Indian Reservations (FDPIR)', description: '', users: '2740 users' },
  { id: 9, value: "E11", title: 'Head Start', description: '', users: '2740 users' },
  { id: 10, value: "E13", title: 'Eligibility Based on Income', description: '', users: '2740 users' },
  { id: 11, value: "E50", title: 'School Lunch/Breakfast Program', description: '', users: '2740 users' },
  { id: 12, value: "E51", title: 'Federal Pell Grant', description: '', users: '2740 users' },
  { id: 13, value: "E54", title: 'Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)', description: '', users: '2740 users' },
]

function get_program_id(program_code){
  for(var i = 0; i < acp_program_list.length; i++){
    if(acp_program_list[i].value === program_code){
      return i;
    }
  }
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ACPTypeSelect(props) {
  
  const [selectedMailingLists, setSelectedMailingLists] = useState(props.selected ? acp_program_list[get_program_id(props.selected)] : "");
  

  return (
    <RadioGroup value={selectedMailingLists} onChange={(e) => {setSelectedMailingLists(e);props.handleSelected(e);}}>
      <RadioGroup.Label className="text-base font-medium text-gray-900">{props.params.label}</RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4">
        {acp_program_list.map((mailingList) => (
          <RadioGroup.Option
            key={mailingList.id}
            value={mailingList}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-rural-4g-2 ring-2 ring-rural-4g-2' : '',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {mailingList.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                      {mailingList.description}
                    </RadioGroup.Description>
                    <div className='m-4'></div>
                    <RadioGroup.Description as="z" className="mt-6 text-sm font-medium text-gray-900 absolute bottom-0 mb-2">
                      <div><button className='border p-2 rounded-md' type="button">Select</button></div>
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-rural-4g-2')}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-rural-4g-2' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
