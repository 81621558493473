import { CheckCircleIcon, CheckIcon } from '@heroicons/react/20/solid'
import OrderLayout from './components/OrderLayout'
import { checkAppIdExists } from "../../net/api";

import Tablet from "./components/Tablet";

import { useState } from 'react';
import CheckTabletOrHome from './components/CheckTabletOrHome';

import { useNavigate } from 'react-router-dom';
import { put_saved_obj_in_zoho_checkout_url } from '../../utils/zoho';
import { setDocumentHead } from '../../utils/document';
import { RadioGroup } from '@headlessui/react'
import DiscountType from './components/DiscountType';


const tiers = [
  {
    name: 'Bronze',
    href: put_saved_obj_in_zoho_checkout_url('https://subscriptions.zoho.com/subscribe/bd1b8a937afc9a283260342459b8e9836a2bba0f6b053217ad482bf477cf719e/DiscountBronze?addon_code%5B0%5D=Router&addon_quantity%5B0%5D=1&addon_code%5B1%5D=Shipping&addon_quantity%5B1%5D=1&coupon_code=ACP30'),
    priceMonthly: 95,
    description: '100GB of 4G data*',
    subdescription: `(may experience slower speeds after 100 GB)`,
    includedFeatures: [
      'Download Speeds Up To 10-35 Mbps',
      'Upload Speeds Up To 3-20 Mbps',
      '1080 Streaming',
      'No Contract',
      'ACP Discount Applied'
    ],
  },
  {
    name: 'Silver',
    href: put_saved_obj_in_zoho_checkout_url('https://subscriptions.zoho.com/subscribe/bd1b8a937afc9a283260342459b8e9836a2bba0f6b053217ad482bf477cf719e/Discountsilver?addon_code%5B0%5D=Router&addon_quantity%5B0%5D=1&addon_code%5B1%5D=Shipping&addon_quantity%5B1%5D=1&coupon_code=ACP30'),
    priceMonthly: 125,
    description: '250GB of 4G data*',
    subdescription: `may experience slower speeds after 250 GB)`,
    includedFeatures: [
      'Download Speeds Up To 10-35 Mbps',
      'Upload Speeds Up To 3-20 Mbps',
      '1080 Streaming',
      'No Contract',
      'ACP Discount Applied'
    ],
  },
  {
    name: 'Gold',
    href: put_saved_obj_in_zoho_checkout_url('https://subscriptions.zoho.com/subscribe/bd1b8a937afc9a283260342459b8e9836a2bba0f6b053217ad482bf477cf719e/Gold?addon_code%5B0%5D=Router&addon_quantity%5B0%5D=1&addon_code%5B1%5D=Shipping&addon_quantity%5B1%5D=1&coupon_code=ACP30'),
    priceMonthly: 155,
    description: '500GB of 4G data*',
    subdescription: `(may experience slower speeds after 500 GB)`,
    includedFeatures: [
      'Download Speeds Up To 10-35 Mbps',
      'Upload Speeds Up To 3-20 Mbps',
      '1080 Streaming',
      'No Contract',
      'ACP Discount Applied'
    ],
  },
  {
    name: 'Diamond',
    href: put_saved_obj_in_zoho_checkout_url('https://subscriptions.zoho.com/subscribe/bd1b8a937afc9a283260342459b8e9836a2bba0f6b053217ad482bf477cf719e/Diamond?addon_code%5B0%5D=Router&addon_quantity%5B0%5D=1&addon_code%5B1%5D=Shipping&addon_quantity%5B1%5D=1&coupon_code=ACP30'),
    priceMonthly: 185,
    description: `1TB of 4G data*`,
    subdescription: ` (may experience slower speeds after 1000 GB)`,
    includedFeatures: [
      'Download Speeds Up To 10-35 Mbps',
      'Upload Speeds Up To 3-20 Mbps',
      '1080 Streaming',
      'No Contract',
      'ACP Discount Applied'
    ],
  },
]

const plan_features = {
  name: 'Home Internet',
  href: put_saved_obj_in_zoho_checkout_url('https://subscriptions.zoho.com/subscribe/bd1b8a937afc9a283260342459b8e9836a2bba0f6b053217ad482bf477cf719e/DiscountBronze?addon_code%5B0%5D=Router&addon_quantity%5B0%5D=1&addon_code%5B1%5D=Shipping&addon_quantity%5B1%5D=1&coupon_code=ACP30'),
  priceMonthly: 75,
  description_1: 'Experience unparalleled quality with our flexible, unlimited internet service offering up to 5G data speeds, now made more affordable with discounts available through the Affordable Connectivity Program.',
  description: '100GB of 4G data*',
  subdescription: `(may experience slower speeds after 100 GB)`,
  includedFeatures: [
    'Download Speeds Up To 200Mbps on 5G',
    'Upload Speeds Up To 50Mbps on 5G',
    'Download Speeds Up To 10-35 Mbps on LTE',
    'Upload Speeds Up To 5-50 Mbps on LTE',
    'Up to 4K Streaming',
    'No Contract',
    'ACP Discount Applied'
  ],
  urls: {
    'acp_discount': 'https://subscriptions.zoho.com/subscribe/bd1b8a937afc9a283260342459b8e9836a2bba0f6b053217ad482bf477cf719e/ACPHOME',
    'tribal_discount': 'https://subscriptions.zoho.com/subscribe/bd1b8a937afc9a283260342459b8e9836a2bba0f6b053217ad482bf477cf719e/TRIBALACPHOME'
  }
};

export default function Plans() {
  const navigate = useNavigate();

  const [plan_info, set_plan_info] = useState({
    type: ""
  });

  const [acp_available, set_acp_available] = useState(true);

  if (!(localStorage && localStorage.getItem("all_conditions_signed"))) {
    navigate("/order/conditions");
  }

  if (!(localStorage && localStorage.getItem("application_done"))) {
    navigate("/order");
  }

  setDocumentHead("Choose your plan | Rural4G - Affordable Connectivity Program");

  return (
    <OrderLayout title="Choose Your Plan">
      <div className="lg:w-1/2 mx-auto">
        <div className="mb-8 mt-4 shadow border p-2 rounded-md">
          <CheckTabletOrHome state={plan_info} fn_state={set_plan_info} />
        </div>
      </div>
      {plan_info && plan_info.type && <>
        {plan_info && plan_info.type && plan_info.type === "home" ? <div>
          <HomeInternetPlans />
        </div> : <div>
          <Tablet />
        </div>}
      </>
      }

    </OrderLayout>
  )
}




const includedFeatures = [
  'Download Speeds Up To 10-35 Mbps',
  'Upload Speeds Up To 3-20 Mbps',
  'Up to 4K Streaming',
  'No Contract',
  'ACP Discount Applied'
]

function HomeInternetPlans() {
  const [discount_type_state, set_discount_type_state] = useState("");
  const tribal_status = localStorage.tribal;
  let discounted_amount = 0;
  if ((tribal_status && tribal_status === "N") || (discount_type_state == "no")) {
    discounted_amount = 30;
  } else if ((tribal_status && tribal_status === "Y") || (discount_type_state == "yes")) {
    discounted_amount = 75;
  }

  return (
    <div className="bg-white pb-24 sm:py-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {(discounted_amount && tribal_status) && (discounted_amount === 75 || discounted_amount === 30) ? <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Congratulations, you have a <span className='text-rural-4g-2'>${discounted_amount}</span> discount</h2>
        </div> :

          <div className="lg:w-1/2 mx-auto">
            <div className='mb-8 mt-4 shadow border p-2 rounded-md'>
              <DiscountType fn_state_2={set_discount_type_state} state_2={discount_type_state}/>
            </div>
          </div>}

        {discounted_amount ? <div className="mx-auto mt-2 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-8 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Home Internet</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              {plan_features && plan_features.description_1}
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-rural-4g-2">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-1 sm:gap-6"
            >
              {plan_features && plan_features.includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">Pay now and get connected</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900"><span className='font-normal text-decoration-line text-gray-500 text-2xl'><s>${plan_features.priceMonthly}</s></span> ${plan_features.priceMonthly - discounted_amount}</span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD /month</span>
                </p>
                <a
                  href={`${discounted_amount === 75 ? plan_features.urls.tribal_discount : plan_features.urls.acp_discount}`}
                  className="mt-10 block w-full rounded-md bg-rural-4g-2 hover:rural-4g-1 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-rural-4g-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Proceed to checkout 
                  {/* ${plan_features.priceMonthly - discounted_amount} */}
                </a>
                <p className="mt-6 text-xs leading-5 text-gray-600">

                </p>
              </div>
            </div>
          </div>
        </div> : ''}

      </div>
    </div>
  )
}



const mailingLists = [
  { id: 1, title: 'Yes', description: 'Last message sent an hour ago', users: '621 users' },
  { id: 2, title: 'No', description: 'Last message sent 2 weeks ago', users: '1200 users' }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function CheckboxDiscountType() {
  const [selectedMailingLists, setSelectedMailingLists] = useState(mailingLists[0])

  return (
    <RadioGroup value={selectedMailingLists} onChange={setSelectedMailingLists}>
      <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
        Do you leave in Tribal Area?
      </RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        {mailingLists.map((mailingList) => (
          <RadioGroup.Option
            key={mailingList.id}
            value={mailingList}
            className={({ active }) =>
              classNames(
                active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {mailingList.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                      {mailingList.description}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-indigo-600' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
