import { Dot } from "react-animated-dots";

export default function CheckAppId(props) {
    

    
    return (
        <div className="mt-8">
            {false && <div  className="text-sm text-left w-full lg:w-1/2 mx-auto pl-3 text-slate-700">
                Enter you application ID
            </div>}

            <div className="w-full mx-auto lg:w-1/2">
                <form onSubmit={props.handleCheckApplicationID} className=" sm:flex  p-3 mx-auto">
                    <input
                        name="appId"
                        type="text"
                        onChange={props.handleChangeInput}
                        required
                        value={props.app_id ? props.app_id : ``}
                        className="w-full rounded-md border-gray-300 px-5 py-3 placeholder-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:max-w sm:flex-initial"
                        placeholder="Enter your ACP application ID"
                    />
                    <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-none">
                        <button
                            type="submit"
                            className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"

                        >
                            {!props.loading_application_id ? `Check ID` : <>Checking <Dot> .</Dot><Dot>.</Dot><Dot>.</Dot> </>}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}