//require('dotenv').config();

const server_url = process.env.REACT_APP_BACK_END;// 'https://b-acpform.rural4g.com';//
//const server_url = 'http://127.0.0.1:4500';

export function post(url, data) {
    return new Promise((resolve, reject) => {
        console.log("Trying to send");
        return fetch(`${server_url}/${url}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
          .then((response) => resolve(response.json()))
          .catch((error) => reject(error));
    });
}

export function get_application_status(check_status_id){
  return new Promise((resolve, reject) => {
    const body = {
      check_id : check_status_id
    };

    return fetch(`${server_url}/eligibility/status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      }, 
      body : JSON.stringify(body)
    })
    .then((response) => resolve(response.json()))
    .catch((error) => reject(error));
  });
}


export function checkZipCodeHasCoverage(type,zip_code){
  return new Promise((resolve, reject) => {
    return fetch(`${server_url}/zip/covered/${type}/${zip_code}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => resolve(response.json()))
    .catch((error) => reject(error));
  });
}

export function checkAppIdExists(app_id){
  return new Promise((resolve, reject) => {
    return fetch(`${server_url}/application/check`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({app_id})
    })
    .then((response) => resolve(response.json()))
    .catch((error) => reject(error));
  });
}

export function saveApplicationConsent(payload){
  return new Promise((resolve, reject) => {
    return fetch(`${server_url}/application/consent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
    .then((response) => resolve(response.json()))
    .catch((error) => reject(error));
  });
}

export function saveToMailchimp(payload){
  return new Promise((resolve, reject) => {
    return fetch(`${server_url}/mailchimp/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
    .then((response) => resolve(response.json()))
    .catch((error) => reject(error));
  });
}

export function send_error_log(payload){
  return new Promise((resolve, reject) => {
    return fetch(`${server_url}/log/senderror`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      }, 
      body : JSON.stringify({error: payload})
    })
    .then((response) => resolve(response.json()))
    .catch((error) => reject(error));
  });
}

export function check_application_identity(payload){
  return new Promise((resolve, reject) => {
    return fetch(`${server_url}/application/verify/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      }, 
      body : JSON.stringify(payload)
    })
    .then((response) => resolve(response.json()))
    .catch((error) => reject(error));
  });
}

export async function verify_the_request_code(request_code){
  return new Promise((resolve, reject) => {
    return fetch(`${server_url}/application/verify/code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      }, 
      body : JSON.stringify({request_code})
    })
    .then((response) => resolve(response.json()))
    .catch((error) => reject(error));
  });
}

export async function submit_email_for_notification(payload){
  return new Promise((resolve, reject) => {
    return fetch(`${server_url}/mailchimp/email_only/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      }, 
      body : JSON.stringify(payload)
    })
    .then((response) => resolve(response.json()))
    .catch((error) => reject(error));
  });
}
