import { months, days } from "./input_structure";
//import { handleChange } from "./Form";

export default function Input(props){

    let years = Array.from(new Array(new Date().getFullYear() - 1920),(val,index) => index + 1920 - 17).reverse();

    if(props.bqp){
        years = Array.from(new Array(new Date().getFullYear() - 1920),(val,index) => index + 1920).reverse();
    }
    
    return (
        <div className="flex-auto my-2 mx-2 w-80">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                {props.params.label} {props.params.required ? <span className="text-red-500">*</span> : null}
            </label>
            <div className="flex">
                <div className="mt-1 mx-2 flex-auto">
                    <select required="true" value={props.month} onChange={(e) => props.handleChange(e, "month", props.submit_form_fieldname)} type="select"  name="month_of_birth" className="relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                        <option disabled="true"></option>
                        {months && months.map((month, index) => {
                            //console.log(month);
                            return <option Key={index} value={month.value}>{month.label}</option>
                            //return <option selected={parseInt(props.month) === parseInt(month.value) ? true : false } Key={index} value={month.value}>{month.label}</option>
                        })
                        }
                    </select>
                </div>
                <div className="mt-1 mx-2 flex-auto">
                    <select required="true" value={props.day ? parseInt(props.day) : ""} onChange={(e) => props.handleChange(e, "day", props.submit_form_fieldname)} type="select" name="day_of_birth" className="relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                        <option disabled="true"></option>
                        {days && days.map((day, index) => {
                                return <option Key={index} value={day}>{day}</option>
                                //return <option selected={parseInt(props.day) === parseInt(day) ? true : false } Key={index} value={day}>{day}</option>
                            })
                        }
                    </select>
                </div>
                <div className="mt-1 mx-2 flex-auto">
                    <select required="true" value={props.year ? parseInt(props.year) : ""} onChange={(e) => props.handleChange(e, "year", props.submit_form_fieldname)} type="select" name="year_of_birth" className="relative block w-full rounded-md border-gray-300 bg-transparent focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                        <option disabled="true"></option>
                        {years && years.map((year, index) => {
                                return <option Key={index} value={year}>{year}</option>
                                //return <option selected={parseInt(props.year) === parseInt(year) ? true : false } Key={index} value={year}>{year}</option>
                            })
                        }
                    </select>
                </div>
            </div>
            
            <p className="mt-2 text-sm text-gray-500" id="email-description">
                {props.params.description}
            </p>
        </div>
    );
}