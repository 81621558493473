import { PaperClipIcon } from '@heroicons/react/20/solid';
import {Link} from "react-router-dom";
import Layout from '../../shared_components/Layout';
import {decode as base64_decode, encode as base64_encode} from "base-64";
import parse from 'html-react-parser';
import DataLine from "./data_line";

export default function DataVerification() {
    const submit_obj = localStorage && localStorage.submit_obj ? JSON.parse(base64_decode(localStorage.submit_obj)) : "";
    console.log(submit_obj);

    const key_labels = {
        "firstName": "First name",
        "middleName": "Middle name",
        "lastName": "Last name",

        "address": "Residential address",
        "state": "State",
        "city": "City",
        "zipCode": "Zip code",
        "urbanizationCode": "Urbanization code", //good

        "dob": "Date of birth",
        "ssn4": "Last 4 digits of your Social Security Number",
        "tribalId": "Tribal ID",

        "bqpFirstName": "Beneficiary first name",
        "bqpLastName": "Beneficiary last name",
        "bqpDob": "Beneficiary date of birth",
        "bqpSsn4": "Last 4 digits of beneficiary Social Security Number",
        "bqpTribalId": "Beneficiary tribal ID",

        "alternateId": "Alternate ID",
        "bqpAlternateId": "Beneficiary alternate ID",

        "eligibilityProgramCode": "Eligibility program code",
        "publicHousingCode": "Public housing code",
        "consentInd": "Consent ID",
        "contactPhoneNumber": "Contact Phone Number",
        "contactEmail": "Contact email",
        "contactAddress": "Contact address",
        "contactCity": "Contact city",
        "contactState": "Contact state",
        "contactZipCode": "Contact Zip Code",
        "contactUrbCode": "Contact urbanization code",
        
        "repId": "",
        "repNotAssisted": "",
        "carrierUrl": ""
    };

    const not_to_be_displayed = ["alternateId", "bqpAlternateId", "publicHousingCode", "consentInd", "repId", "repNotAssisted", "carrierUrl"];
    let counter = 0;
  return (
    <Layout title="Verify your information">
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">

        <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Verify your information before submit</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
        </div>
        <div className="border-t border-gray-200">
            <dl>
                {Object.keys(submit_obj).map((key, i) => submit_obj[key] && !not_to_be_displayed.includes(key) && <DataLine elt_name={key_labels[key]} elt_value={submit_obj[key]} type={ counter++ % 2 === 0 ?  "pair" : "impair"}/>)}
            </dl>

            <div className="m-10">
                        <Link to="/verif">
                            <button type="button" className="border rounded-md bg-slate-800 text-white p-2 px-4">
                                Previous
                            </button>
                        </Link>
                        <Link to="/final">
                            <button type="button" className="border rounded-md bg-rural-4g-2 text-white p-2 px-4">
                                Submit & next
                            </button>
                        </Link>
            </div>
        </div>
        </div>
    </Layout>
  )
}
