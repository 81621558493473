import { ArrowLeftIcon, BackwardIcon } from '@heroicons/react/20/solid';
import r4glogo from '../../../res/img/R4G-logo.png';
import { Link, useNavigate } from 'react-router-dom';

export default function OrderLayout(props){
    const navigate = useNavigate();

    return (
        <div className="min-h-full">
                <div className="text-left">
                    <Link to="/"><img src={r4glogo} className="w-32 mx-32 my-5 inline" alt="Rural 4G logo" /></Link>
                </div>

                <main className="text-center">
                    <div className='mx-auto max-w-7xl sm:px-6 lg:px-8 mb-80'>
                        <p className="mt-2 mb-3 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl relative">
                            <span onClick={() => navigate(-1)} className='absolute bottom-10 md:bottom-0 left-0 text-xl font-light bg-slate-100 py-2 px-3 rounded-md hover:bg-orange-200 cursor-pointer'> 
                                <ArrowLeftIcon className='inline w-5 relative bottom-1'/><span className=''> Go back</span>
                            </span> 
                            {props.title}
                        </p>

                        <div>
                            {props.children}
                        </div>
                    </div>
                </main>
            </div>
    );
}