import {decode as base64_decode} from "base-64";

const usa_states = [
    {
        "name": "",
        "abbreviation": ""
    },
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
];

const saved_values = localStorage.submit_obj;

export const input_fields_line_1 = [
    {
        label: "First Name",
        type: "text",
        name: "firstName",
        placeholder: "First Name",
        ariadescribedby: "first-description",
        description:"",
        required : true
    },{
        label: "Middle Name",
        type: "text",
        name: "middleName",
        placeholder: "Middle Name",
        ariadescribedby: "middle-description",
        description:"",
        required : false
    }, {
        label: "Last Name",
        type: "text",
        name: "lastName",
        placeholder: "Last Name",
        ariadescribedby: "middle-description",
        description:"",
        required : true
    }/*, 
    {
        label: "Suffix",
        type: "select",
        name: "suffix_name",
        placeholder: "Suffix Name",
        ariadescribedby: "suffix-description",
        description:"",
        required : false,
        options : [
            {label:"Jr",value:"Jr"},
            {label:"Sr",value:"Sr"},
            {label:"I",value:"I"},
            {label:"II",value:"II"},
            {label:"III",value:"III"},
            {label:"IV",value:"IV"},
            {label:"V",value:"V"}
        ]
    },*/
     
];

//years from 1920 to 2021
export const years = Array.from(new Array(new Date().getFullYear() - 1920),(val,index) => index + 1920).reverse();

export const months = [
    {label:"January",value:"01"},
    {label:"February",value:"02"},
    {label:"March",value:"03"},
    {label:"April",value:"04"},
    {label:"May",value:"05"},
    {label:"June",value:"06"},
    {label:"July",value:"07"},
    {label:"August",value:"08"},
    {label:"September",value:"09"},
    {label:"October",value:"10"},
    {label:"November",value:"11"},
    {label:"December",value:"12"}
];

export const days = Array.from(new Array(31),(val,index)=>index+1);

export const input_fields_line_2 = [
    /*{
        label: "APT/Floor/Other",
        type: "text",
        name: "aptFloor",
        placeholder: "APT/Floor/Other",
        ariadescribedby: "apt-description",
        description:"",
        required : true
    },*/
    {
        label: "City",
        type: "text",
        name: "city",
        placeholder: "City",
        ariadescribedby: "city-description",
        description:"",
        required : true
    },{
        label: "State",
        type: "select",
        name: "state",
        placeholder: "State",
        ariadescribedby: "state-description",
        description:"",
        options: usa_states.map((state) => {
            return {
                    label:state.name,
                    value:state.abbreviation
            };
        }),
        required : true
    },
     
];

export const input_fields_line_3 = [
    {
        label: "Beneficiary First Name",
        type: "text",
        name: "bqpFirstName",
        placeholder: "First Name",
        ariadescribedby: "first-description",
        description:"",
        required : true
    },/*{
        label: "Beneficiary middle name",
        type: "text",
        name: "bqpMiddleName",
        placeholder: "Middle Name",
        ariadescribedby: "middle-description",
        description:"",
        required : true
    }, */{
        label: "Beneficiary Last Name",
        type: "text",
        name: "bqpLastName",
        placeholder: "Last Name",
        ariadescribedby: "middle-description",
        description:"",
        required : true
    }/*, 
    {
        label: "Suffix",
        type: "select",
        name: "suffix_name",
        placeholder: "Suffix Name",
        ariadescribedby: "suffix-description",
        description:"",
        required : false,
        options : [
            {label:"Jr",value:"Jr"},
            {label:"Sr",value:"Sr"},
            {label:"I",value:"I"},
            {label:"II",value:"II"},
            {label:"III",value:"III"},
            {label:"IV",value:"IV"},
            {label:"V",value:"V"}
        ]
    },*/
     
];

export const input_fields_line_4 = [
/*{
        label: "APT/Floor/Other",
        type: "text",
        name: "otherAptFloor",
        placeholder: "APT/Floor/Other",
        ariadescribedby: "apt-description",
        description:"",
        required : true
    },*/
    {
        label: "City",
        type: "text",
        name: "otherCity",
        placeholder: "City",
        ariadescribedby: "city-description",
        description:"",
        required : true
    },{
        label: "State",
        type: "select",
        name: "otherStateShipping",
        placeholder: "State",
        ariadescribedby: "state-description",
        description:"",
        options: usa_states.map((state) => {
            return {
                    label:state.name,
                    value:state.abbreviation
            };
        }),
        required : true
    },
     
];