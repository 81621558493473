import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'

const plans = [
    { name: 'ATT', ram: '', cpus: '', disk: '', price: '' },
    { name: 'Verizon', ram: '', cpus: '', disk: '', price: '' },
    { name: 'T-Mobile', ram: '', cpus: '', disk: '', price: '' },
    { name: 'Sprint', ram: '', cpus: '', disk: '', price: '' }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CarrierList(props) {
  const [selected, setSelected] = useState(null);

    const handleSelectNetwork = (network) => {
        props.set_state_fn({
            ...props.state,
            cellular_carrier_name : network
        });
    }


  return (
    <div className='pt-8'>
        <RadioGroup value={selected} onChange={setSelected}>
        <RadioGroup.Label> Select the network carrier available at your place </RadioGroup.Label>
        <div className="pt-2 grid-cols-12 grid">
            {plans.map((plan) => (
            <RadioGroup.Option
                key={plan.name}
                value={plan}
                onClick={() => handleSelectNetwork(plan.name)}
                className={({ checked, active }) =>
                classNames(
                    checked ? 'border-transparent' : 'border-gray-300',
                    active ? 'border-rural-4g-2 ring-2 ring-rural-4g-2' : '',
                    'relative col-span-6 cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none my-2 mx-2'
                )
                }
            >
                {({ active, checked }) => (
                <>
                    <span className="flex items-center">
                    <span className="flex flex-col text-sm">
                        <RadioGroup.Label as="span" className="font-medium text-gray-900">
                        {plan.name}
                        </RadioGroup.Label>
                    </span>
                    </span>
                    <RadioGroup.Description
                    as="span"
                    className="mt-2 flex text-sm sm:mt-0 sm:ml-4 sm:flex-col sm:text-right"
                    >
                    <span className="font-medium text-gray-900">{plan.price}</span>
                    <span className="ml-1 text-gray-500 sm:ml-0">Select</span>
                    </RadioGroup.Description>
                    <span
                    className={classNames(
                        active ? 'border' : 'border-2',
                        checked ? 'border-rural-4g-2' : 'border-transparent',
                        'pointer-events-none absolute -inset-px rounded-lg'  
                    )}
                    aria-hidden="true"
                    />
                </>
                )}
            </RadioGroup.Option>
            ))}
        </div>
        </RadioGroup>
    </div>
  )
}
