import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/24/solid'
import {Link} from "react-router-dom";

import r4glogo from '../res/img/R4G-logo.png';
import '../styles/main.css';

import StepsNavigation from './StepsNavigation';
import TheMenus from '../pages/selectProgram/TheMenus';

export default function Layout(props){
    const get_check_button = () => {
        if(window.location.pathname === "/status-check"){
            return (
                <div className='md:flex-auto my-4 text-right'>
                            <div>Haven't applied yet? 
                            <Link to="/coverage"><button className='ml-2 bg-green-500 text-white px-4 py-2 rounded-md'>Check your eligibility</button></Link>
                            </div>
                    </div>
            );
        }else {
            return (
                <div className='md:flex-auto my-4 text-right'>
                            <div>Already applied for ACP with Rural4G? 
                            <Link to="/status-check"><button className='ml-2 bg-green-500 text-white px-4 py-2 rounded-md'>Check your application status </button></Link>
                            </div>
                    </div>
            );
        }
    }

    return (
        <>
            
            <div className="min-h-full">
                <div className='hidden md:block'>
                    <Link to="/"><img src={r4glogo} className="w-32 mx-32 my-5 inline" alt="Rural 4G logo" /></Link>
                </div>

                <div className="py-2">
                <header className='hidden md:flex md:mx-36'>
                    <div className="md:flex-auto mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-5">
                        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">{props.title}</h1>
                    </div>
                    
                    {get_check_button()}
                    
                </header>
                <main>
                    <div className='mx-auto max-w-7xl sm:px-6 lg:px-8 mb-80'>
                        
                        {props.hide_steps ? '' : <StepsNavigation/>}
                        {props.children}
                    </div>
                </main>
                </div>
            </div>
        </>
    );
}