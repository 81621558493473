import { CheckIcon } from '@heroicons/react/24/outline'
import { useState } from 'react';
import { put_saved_obj_in_zoho_checkout_url } from '../../../utils/zoho';

const features = [
  'High speed 4G connectivity',
  'Compatibility with all major US carriers',
  'Long battery life',
  'Powerful camera',
  'Read e-books',
  'Online shopping ',
  'Online Learning',
  'Powerful device',
  'Durable design'
]

const tiers = [
  {
    name: 'Free',
    href: put_saved_obj_in_zoho_checkout_url('https://subscriptions.zoho.com/subscribe/bd1b8a937afc9a283260342459b8e9836a2bba0f6b053217ad482bf477cf719e/ACPTAB?addon_code%5B0%5D=ACPTABLET&addon_quantity%5B0%5D=1'),
    priceMonthly: 0,
    description: '$10.10 one-time fee for Tablet',
    subdescription : ``,
    includedFeatures: [
      '10GB Internet Data Cap', 
      'Download Speeds Up To 10-35 Mbps',
      'Upload Speeds Up To 3-20 Mbps',
      'Tablet Included',
      'ACP Discount Applied'
    ],
  },
  {
    name: 'Unlimited',
    href: put_saved_obj_in_zoho_checkout_url('https://subscriptions.zoho.com/subscribe/bd1b8a937afc9a283260342459b8e9836a2bba0f6b053217ad482bf477cf719e/UNLIMITED?addon_code%5B0%5D=ACPTABLET&addon_quantity%5B0%5D=1'),
    priceMonthly: 12,
    description: '$10.10 one-time fee for Tablet',
    subdescription : ``,
    includedFeatures: [
      'Unlimited Internet',
      'Download Speeds Up To 10-35 Mbps',
      'Upload Speeds Up To 3-20 Mbps',
      'Tablet Included',
      'ACP Discount Applied'
    ],
  },
  {
    name: 'Tribal',
    href: put_saved_obj_in_zoho_checkout_url('https://subscriptions.zoho.com/subscribe/bd1b8a937afc9a283260342459b8e9836a2bba0f6b053217ad482bf477cf719e/TribalACP?addon_code%5B0%5D=ACPTABLET&addon_quantity%5B0%5D=1'),
    priceMonthly: 0,
    description: '$10.10 one-time fee for Tablet',
    subdescription : ``,
    includedFeatures: [
      'Unlimited Internet',
      'Download Speeds Up To 10-35 Mbps',
      'Upload Speeds Up To 3-20 Mbps',
      'ACP Discount Applied',
      //'Tablet Included (only for households on qualifying Tribal lands)',
    ],
    other_description: '(only for households on qualifying Tribal lands)'
  },
]

export default function Tablet() {
  const [acp_available, set_acp_available] = useState(false);

  return (
    <div className="bg-white shadow border p-2 rounded-md">
        <div className="mx-auto max-w-7xl pt-4 py-24 px-6 lg:px-8">
          <div className="sm:align-center sm:flex sm:flex-col">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-center">Tablet Internet Pricing Plans</h1>
            <p className="mt-5 text-xl text-gray-500 sm:text-center">
            Choose the plan that's right for you.
            </p>
          </div>
          <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
            {tiers.map((tier) => (
              <div key={tier.name} className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm">
                <div className="p-6">
                  <h2 className="text-lg font-medium leading-6 text-gray-900">{tier.name}</h2>
                  <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                  <p className="mt-4 text-xs text-gray-500">{tier.subdescription}</p>
                  <p className="mt-8">
                    {acp_available && <span className="text-base font-xl mr-2 text-gray-500 line-through">${tier.priceMonthly}</span> }
                    <span className="text-4xl font-bold tracking-tight text-gray-900">${acp_available ? tier.priceMonthly - 30 : tier.priceMonthly}</span>{' '}
                    <span className="text-base font-medium text-gray-500">/mo</span>
                  </p>
                  <a
                    href={tier.href}
                    target="_blank"
                    className="mt-8 block w-full rounded-md border border-gray-800 bg-gray-800 py-2 text-center text-sm font-semibold text-white hover:bg-gray-900"
                  >
                    Start today
                  </a>
                </div>
                <div className="px-6 pt-6 pb-8">
                  <h3 className="text-sm font-medium text-gray-900">What's included</h3>
                  <ul role="list" className="mt-6 space-y-4 text-left">
                    {tier.includedFeatures.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon className="h-5 w-5 flex-shrink-0 text-green-500" aria-hidden="true" />
                        <span className="text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                {tier.other_description && <div className='py-5'>
                  <p className="text-sm text-gray-500">{tier.other_description}</p>
                </div>}
              </div>
            ))}
          </div>
        </div>
      </div>
  );
}

function Tablet1() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
        <div className="pb-2 xl:flex xl:items-center xl:justify-between">
          <div className='text-left'>
            <div>
              <span className='line-through text-2xl'>$ 125.00</span>
            </div>
            <h1 className="text-4xl font-bold tracking-tight sm:text-5xl">
              <span className="text-gray-900">An entire year Internet + a tablet for </span>

              <span className="text-indigo-600">$10.10</span>
            </h1>
            <p className="mt-5 text-xl text-gray-500">
              A brand new tablet and a year of Internet for only $10.10. That's less than $1 a month.
            </p>
            
          </div>
          
        </div>
        <div className='text-left pb-4'>
          <a
            href="https://subscriptions.zoho.com/hostedpage/2-76bc6761ce4a25057cc609f86cdb584aaeb2ac4616d79250d7c8f5d8c7968bf5f9b262a7eff87f729f717d3316517395/checkout"
            className="mt-8 inline-flex w-full rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700 sm:mt-10 sm:w-auto xl:mt-0"
          >
            Proceed to checkout
          </a>
          </div>
        <div className="border-t border-gray-200 pt-16 xl:grid xl:grid-cols-3 xl:gap-x-8">
          <div>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">The Freedom Tablet RG1</p>
            <p className="mt-4 text-lg text-gray-500">
              The Freedom Tablet RG1 is packed with features, including a powerful camera, high-speed 4G connectivity, and compatibility with all major US carriers.
            </p>
          </div>
          <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:col-span-2 xl:mt-0">
            <ul role="list" className="divide-y divide-gray-200">
              {features.slice(0, 5).map((feature, featureIdx) =>
                featureIdx === 0 ? (
                  <li key={feature} className="flex py-4 md:py-0 md:pb-4">
                    <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-base text-gray-500">{feature}</span>
                  </li>
                ) : (
                  <li key={feature} className="flex py-4">
                    <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-base text-gray-500">{feature}</span>
                  </li>
                )
              )}
            </ul>
            <ul role="list" className="divide-y divide-gray-200 border-t border-gray-200 md:border-t-0">
              {features.slice(5).map((feature, featureIdx) =>
                featureIdx === 0 ? (
                  <li key={feature} className="flex py-4 md:border-t-0 md:py-0 md:pb-4">
                    <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-base text-gray-500">{feature}</span>
                  </li>
                ) : (
                  <li key={feature} className="flex py-4">
                    <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-base text-gray-500">{feature}</span>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
