import {useState, useRef} from "react";

import Input from "./Input";
import DatePicker from "./DatePicker";
import {input_fields_line_1,input_fields_line_2, input_fields_line_3, input_fields_line_4} from "./input_structure";
import Checkbox from "./Checkbox";
import ACPTypeSelect from "./ACPTypeSelect";
import {Link, useHistory, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { usa_states } from "./usa_states";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import DialogMessage from "../../../shared_components/DialogMessage";
import WarningAlert from "../../../utils/alerts";

var validateDate = require("validate-date");


const goToNextPage = () => {
    window.location.href = "/confirm";
}

//let submit_obj = {};


const terms_and_conditions = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla arcu mi, sagittis sit amet dignissim a, semper at nisl. Vestibulum non venenatis ex. Proin mattis est sed nunc consectetur, non condimentum neque feugiat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent ultricies quis ipsum mattis egestas. Pellentesque facilisis ultricies risus, eu interdum felis lobortis malesuada. Cras sagittis mattis magna vitae vehicula. Etiam sollicitudin, nisl nec rhoncus facilisis, nisl turpis dapibus lorem, id porttitor sapien sem sed elit. Ut iaculis dictum erat non ultrices. Cras facilisis nisi vitae ex tristique, quis viverra est lacinia.

Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras finibus eros eget rhoncus condimentum. Cras non luctus risus. In pretium finibus nisi vel pharetra. Vivamus semper neque in scelerisque viverra. Proin nec felis non mi vehicula interdum vel ut sapien. Curabitur posuere libero nec diam rhoncus ullamcorper. Aliquam ut mollis nulla. Suspendisse bibendum urna vel urna bibendum, pulvinar condimentum lacus aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam fringilla pharetra massa, ut consectetur ipsum malesuada sagittis. Praesent libero nisi, imperdiet eget quam at, consectetur tincidunt mi. Vivamus sollicitudin urna sed nisi malesuada, et lobortis velit vestibulum. Suspendisse in turpis tincidunt, pellentesque orci vitae, suscipit arcu. Cras pellentesque volutpat tellus, non blandit tellus rhoncus at. Pellentesque euismod enim ligula, rutrum accumsan velit tempus a.`;

/*export function handleChange(e){
    submit_obj[e.target.name] = e.target.value;
    payload_to_be_sent[e.target.name] = e.target.value;
    console.log(submit_obj);
}*/

export default function Form() {
    const { t, i18n } = useTranslation();
    const [firstName, setFirstName] = useState("");
    const [show_urbanization_code, set_show_urbanization_code] = useState(false);
    const [show_zip_code, setShowZipCode] = useState(true);
    const [phone_number_required, setPhoneNumberRequired] = useState(true);
    const [email_addr_required, setEmailAddrRequired] = useState(false);
    const [show_beneficiary_section, set_show_beneficiary_section] = useState(false);
    const [show_error_msg, set_show_error_msg] = useState(false);
    const [show_shipping_address_section, set_show_shipping_address] = useState(false);
    
    const [show_ship_urbanization_code, set_ship_show_urbanization_code] = useState(false);
    const [show_ship_zip_code, setShipShowZipCode] = useState(true);

    const navigate = useNavigate();

    const [warning_message, set_warning_message] = useState("");

    const saved_values = localStorage.submit_obj && JSON.parse(base64_decode(localStorage.submit_obj));
    
    const [show_tribal_id_zone, set_show_tribal_id_zone] = useState(saved_values && saved_values.alternateId && saved_values.alternateId === "1" ? true : false);
    const [show_tribal_id_zone_bqp, set_show_tribal_id_zone_bqp] = useState(saved_values && saved_values.bqpAlternateId && saved_values.bqpAlternateId === "1" ? true : false);

    //console.log("Saved values are ",saved_values);
    const [default_vals, set_first_name_value] = useState({
        "salutation": saved_values && saved_values.salutation ? saved_values.salutation : "",
        "firstName": saved_values && saved_values.firstName ? saved_values.firstName : "",
        "middleName": saved_values && saved_values.middleName ? saved_values.middleName : "",
        "lastName": saved_values && saved_values.lastName ? saved_values.lastName : "",

        "address": saved_values && saved_values.address ? saved_values.address : "",
        "state": saved_values && saved_values.state ? saved_values.state : "",
        "city": saved_values && saved_values.city ? saved_values.city : "",
        "zipCode": saved_values && saved_values.zipCode ? saved_values.zipCode : "",
        "urbanizationCode": saved_values && saved_values.urbanizationCode ? saved_values.urbanizationCode : "",

        "otherAptFloor": saved_values && saved_values.otherAptFloor ? saved_values.otherAptFloor : "",
        "otherAddress": saved_values && saved_values.otherAddress ? saved_values.otherAddress : "",
        "otherState": saved_values && saved_values.otherState ? saved_values.otherState : "",
        "otherCity": saved_values && saved_values.otherCity ? saved_values.otherCity : "",
        "otherZipCode": saved_values && saved_values.otherZipCode ? saved_values.otherZipCode : "",
        "otherUrbanizationCode": saved_values && saved_values.otherUrbanizationCode ? saved_values.otherUrbanizationCode : "",

        

        "dob": saved_values && saved_values.dob ? saved_values.dob :"",
        "dayDob": saved_values && saved_values.dayDob ? saved_values.dayDob :"",
        "monthDob": saved_values && saved_values.monthDob ? saved_values.monthDob :"",
        "yearDob": saved_values && saved_values.yearDob ? saved_values.yearDob :"",

        "ssn4": saved_values && saved_values.ssn4 ? saved_values.ssn4 : "",
        "tribalId": saved_values && saved_values.tribalId ? saved_values.tribalId : "",

        "bqpFirstName": saved_values && saved_values.bqpFirstName ? saved_values.bqpFirstName : "",
        "bqpLastName": saved_values && saved_values.bqpLastName ? saved_values.bqpLastName : "",
        
        "bqpDob": saved_values && saved_values.bqpDob ? saved_values.bqpDob : "",
        "dayBqpDob": saved_values && saved_values.dayBqpDob ? saved_values.dayBqpDob : "",
        "monthBqpDob": saved_values && saved_values.monthBqpDob ? saved_values.monthBqpDob : "",
        "yearBqpDob": saved_values && saved_values.yearBqpDob ? saved_values.yearBqpDob : "",
        
        
        "bqpSsn4": saved_values && saved_values.bqpSsn4 ? saved_values.bqpSsn4 : "",
        "bqpTribalId": saved_values && saved_values.bqpTribalId ? saved_values.bqpTribalId : "",
        "alternateId": saved_values && saved_values.alternateId && saved_values.alternateId ? saved_values.alternateId : "",
        "bqpAlternateId": saved_values && saved_values.bqpAlternateId && saved_values.bqpAlternateId ? saved_values.bqpAlternateId : "",
        "eligibilityProgramCode": saved_values && saved_values.eligibilityProgramCode ? saved_values.eligibilityProgramCode : "",
        "publicHousingCode": saved_values && saved_values.publicHousingCode ? saved_values.publicHousingCode : "",
        "consentInd": "",
        "contactPhoneNumber": saved_values && saved_values.contactPhoneNumber ? saved_values.contactPhoneNumber : "",
        "contactEmail": saved_values && saved_values.contactEmail ? saved_values.contactEmail : "",
        "contactAddress": saved_values && saved_values.contactAddress ? saved_values.contactAddress : "",
        "contactCity": saved_values && saved_values.contactCity ? saved_values.contactCity : "",
        "contactState": saved_values && saved_values.contactState ? saved_values.contactState : "",
        "contactZipCode": saved_values && saved_values.contactZipCode ? saved_values.contactZipCode : "",
        "contactUrbCode": saved_values && saved_values.contactUrbCode ? saved_values.contactUrbCode : "",
        "repId": saved_values && saved_values.repId ? saved_values.repId : "",
        "repNotAssisted": saved_values && saved_values.repNotAssisted ? saved_values.repNotAssisted : "",
        "carrierUrl": saved_values && saved_values.carrierUrl ? saved_values.carrierUrl : "",
        "wayToReach" : saved_values && saved_values.wayToReach ? saved_values.wayToReach : "",
        "residenceType": saved_values && saved_values.residenceType ? saved_values.residenceType : "",
        "aptFloor" : saved_values && saved_values.aptFloor ? saved_values.aptFloor : "",
        "applyForSomeoneElse" : saved_values && saved_values.applyForSomeoneElse ? saved_values.applyForSomeoneElse : false,
        "useAnotherShippingAddress"  : saved_values && saved_values.useAnotherShippingAddress ? saved_values.useAnotherShippingAddress : false
    });

    //console.log("String here ",JSON.stringify(default_vals));

    const dob = saved_values && saved_values.dob ? new Date(saved_values.dob) : new Date("");

    const [validation_obj_state, set_valid_state] = useState({
        "salutation": "",
        "firstName": "",
        "middleName": "",
        "lastName": "",

        "address": "",
        "state": "",
        "city": "",
        "zipCode": "",
        "urbanizationCode": "", //good

        "dob": "",
        "ssn4": "",
        "tribalId": "",

        "bqpFirstName": "",
        "bqpLastName": "",
        "bqpDob": "",
        "bqpSsn4": "",
        "bqpTribalId": "",
        "alternateId": "",
        "bqpAlternateId": "",
        "eligibilityProgramCode": "",
        "publicHousingCode": "",
        "consentInd": "",
        "contactPhoneNumber": "",
        "contactEmail": "",
        "contactAddress": "",
        "contactCity": "",
        "contactState": "",
        "contactZipCode": "",
        "contactUrbCode": "",
        "repId": "",
        "repNotAssisted": "",
        "carrierUrl": ""
    });
    
    var submit_obj = {
        "salutation": "",
        "firstName": "",
        "middleName": "",
        "lastName": "",

        "address": "",
        "state": "",
        "city": "",
        "zipCode": "",
        "urbanizationCode": "", //good

        "dob": "",
        "ssn4": "",
        "tribalId": "",

        "bqpFirstName": "",
        "bqpLastName": "",
        "bqpDob": "",
        "bqpSsn4": "",
        "bqpTribalId": "",
        "alternateId": "1",
        "bqpAlternateId": "1",
        "eligibilityProgramCode": "",
        "publicHousingCode": "",
        "consentInd": "",
        "contactPhoneNumber": "",
        "contactEmail": "",
        "contactAddress": "",
        "contactCity": "",
        "contactState": "",
        "contactZipCode": "",
        "contactUrbCode": "",
        "repId": "",
        "repNotAssisted": "",
        "carrierUrl": ""
    };


    let validation_obj = {
        "firstName": "[\\w\\.\\-\\s]{2,}",
        "middleName": "[\\w\\.\\-\\s]{2,}",
        "lastName": "[\\w\\.\\-\\s]{2,}",

        "address": "[\\w\\.\\-\\s]{2,}",
        "state": "(A-Z){2,}",
        "city": "[\\w\\.\\-\\s]{2,}",
        "zipCode": "^\\d{5}(?:[-\\s]\\d{4})?$",
        "urbanizationCode": "", //good

        "dob": "",
        "ssn4": "^[0-9]{4}$",
        "tribalId": "",

        "bqpFirstName": "[\\w\\.\\-\\s]{2,}",
        "bqpLastName": "[\\w\\.\\-\\s]{2,}",
        "bqpDob": "",
        "bqpSsn4": "^[0-9]{4}$",
        "bqpTribalId": "",
        "alternateId": "1",
        "bqpAlternateId": "1",
        "eligibilityProgramCode": "",
        "publicHousingCode": "",
        "consentInd": "",

        "contactPhoneNumber": "^((\\([0-9]{3}\\))|[0-9]{3})[\\s\\-]?[\0-9]{3}[\\s\\-]?[0-9]{4}$",//"^[0-9]{10}$",
        "contactEmail": "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
        "contactAddress": "",
        "contactCity": "",
        "contactState": "",
        "contactZipCode": "^([a-zA-Z][0-9][a-zA-Z])\\s*([0-9][a-zA-Z][0-9])$",
        "contactUrbCode": "",
        "repId": "",
        "repNotAssisted": "",
        "carrierUrl": ""
    };

    const [open_dialog, set_open_dialog] = useState(false);

    const useTribalIdOrSSN = (e) => {
        console.log(e.target.checked);
        if(e.target.name === "use-tribal-id-instead-of-ssn"){
            set_show_tribal_id_zone(true);
            set_first_name_value({...default_vals,"alternateId" : "1"});
            set_first_name_value({...default_vals,"ssn4" : ""});
        }else if(e.target.name === "use-ssn-id-instead-of-tribal-id"){
            set_show_tribal_id_zone(false);
            set_first_name_value({...default_vals,"alternateId" : "0"});
            set_first_name_value({...default_vals,"tribalId" : ""});
        }
        
    }

    const handleCloseDialog = () => {
        set_open_dialog(false);
    }
    
    const handleRestartApplication = () => {
        localStorage.clear();
        window.location.reload();
        set_open_dialog(false);
    }

    const handleClickRestartApplication = () => {
        set_open_dialog(true);
    }

    const useTribalIdOrSSN_bqp = (e) => {
        console.log(e.target.checked);
        if(e.target.name === "use-tribal-id-instead-of-ssn-bqp"){
            set_show_tribal_id_zone_bqp(true);
            set_first_name_value({...default_vals,"bqpAlternateId" : "1"});
            set_first_name_value({...default_vals,"bqpSsn4" : ""});
        }else if(e.target.name === "use-ssn-id-instead-of-tribal-id-bqp"){
            set_show_tribal_id_zone_bqp(false);
            set_first_name_value({...default_vals,"bqpAlternateId" : "0"});
            set_first_name_value({...default_vals,"bqpTribalId" : ""});
        }
        
    }
    
    function handleChange(e){
        //console.log("Toujours ===> ",e.target.name, e.target.value);
        localStorage.personal_info_done = false;
        if(e.target.name === "contactPhoneNumber"){
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
            if(e.target.value[0] === "1"){
                e.target.value = e.target.value.slice(1);
            }
        }
        set_first_name_value({...default_vals,[e.target.name] : e.target.value});
        
        if(e.target.name === "state"){
            if(e.target.value === "PR"){
                setShowZipCode(false);
                set_show_urbanization_code(true);
            }else{
                setShowZipCode(true);
                set_show_urbanization_code(false);
            }
        }

        //const value = default_vals;
        //alert(validation_obj[e.target.name]);
        //console.log("Regex is ",validation_obj[e.target.name]);
        const regx = new RegExp(validation_obj[e.target.name]);
        const resp = regx.exec(e.target.value);
        console.log(resp, e.target.value, e.target.name, validation_obj[e.target.name]);
        if(resp){
            set_valid_state({...validation_obj_state,[e.target.name] :'y'});
        }else{
            set_valid_state({...validation_obj_state,[e.target.name] :'n'});
        }
        
        

        if(e.target.name === "stateShipping"){
            if(e.target.value === "PR"){
                setShipShowZipCode(false);
                set_ship_show_urbanization_code(true);
            }else{
                setShipShowZipCode(true);
                set_ship_show_urbanization_code(false);
            }
        }
        

        

        //localStorage.submit_obj = base64_encode(JSON.stringify(default_vals));
        
    }

    var the_date = {year:"", month: "", day: ""};
    const handleDateSelect = (e, date_part, submit_form_field_name) => {
        localStorage.personal_info_done = false;
        set_first_name_value({...default_vals,[date_part + submit_form_field_name] : e.target.value});
    }

    function reInitBeneficiaryFields(){
        set_first_name_value({...default_vals,["bqpFirstName"]:""});
    }

    function handleClickCheckBox(e){
        localStorage.personal_info_done = false;
        if(e.target.name === "applyForSomeoneElse" && e.target.checked){
            set_show_beneficiary_section(true);
            set_first_name_value({...default_vals,["applyForSomeoneElse"] : true});

        }else{
            try{
            console.log("Running the section");
            
            setTimeout(function(){
                //reInitBeneficiaryFields();
            },10);

            /*var obj = {
                "bqpFirstName":"",
                "bqpLastName":"",
                "bqpDob":"",
                "bqpSsn4":"",
                "bqpTribalId":"",
                "bqpAlternateId":"",
                "applyForSomeoneElse":false
            };*/
            
            var obj = {
                ...default_vals,
                "applyForSomeoneElse":false,
                "bqpFirstName":"",
                "bqpLastName":"",
                "bqpDob":"",
                "bqpSsn4":"",
                "bqpTribalId":"",
                "bqpAlternateId":"",
            }

            //set_show_beneficiary_section(false);
            set_first_name_value(obj);
            
            }catch(ex){
                console.log(ex);
            }
            
        }
    }

    function showZoneShippingAddr(e){
        //alert(e.target.name);
        if(e.target.name === "useAnotherShippingAddress" && e.target.checked){
            set_show_shipping_address(true);
            set_first_name_value({...default_vals,["useAnotherShippingAddress"] : true});
        }else{
            set_show_shipping_address(false);
            set_first_name_value({...default_vals,["useAnotherShippingAddress"] : false});
        }
    }

    const choose_program = (e) => {
        console.log("CHOOSE PROGRAM",e);
        submit_obj["eligibilityProgramCode"] = e.value;
    }

    const handleChangeRadioWay = (e) => {
        localStorage.personal_info_done = false;
        set_first_name_value({...default_vals,[e.target.name] : e.target.value});
            if(e.target.value === "phone" || e.target.value === "text-msg"){
                console.log("The phone");
                setPhoneNumberRequired(true);
                setEmailAddrRequired(false);
            }else if(e.target.value === "email"){
                console.log("The email");
                setEmailAddrRequired(true);
                setPhoneNumberRequired(false);
            }else{
                setPhoneNumberRequired(false);
                setEmailAddrRequired(false);
            }
        
    }

    const handleChangeRadio = (e) => {

        localStorage.personal_info_done = false;
        set_first_name_value({...default_vals,[e.target.name] : e.target.value});
    }

    const beneficiary_zone_classnames = show_beneficiary_section ? "bg-gray-100 rounded-md p-2" : "hidden";

    const handleFormSubmit = (e) => {
        e.preventDefault();
        //alert(JSON.stringify(default_vals));
        console.log(default_vals);
        if(default_vals.applyForSomeoneElse){
            if(default_vals.firstName === default_vals.bqpFirstName && default_vals.lastName === default_vals.bqpLastName){
                set_warning_message("The beneficiary's first name and last name cannot be the same as yours.");
                return;
            }
        }
        if(warning_message){
            set_warning_message("");
        }
        //return;
        localStorage.submit_obj = base64_encode(JSON.stringify(default_vals));
        //localStorage.personal_info_done && localStorage.removeItem("personal_info_done");
        //localStorage.errors && localStorage.removeItem("errors");
        localStorage.personal_info_done = true;
        //window.location.href = "/select-program";
        navigate("/select-program");

    }

    const _usac_errors = localStorage.errors && JSON.parse(localStorage.errors);

    console.log(_usac_errors);

    return (
        <form onSubmit={handleFormSubmit}>
            {localStorage.errors && (<div className="bg-red-100 rounded-md p-2">
                Please, fix the errors highlighted below.
                {_usac_errors && _usac_errors.map((error, index) => {
                    return <div key={index}><small>{error.description}</small></div>
                })}
            </div>)}

            <div className="md:flex">

                <div>
                    <Input  
                        className="inline-block"
                        selected={default_vals["salutation"]}
                        required={false}
                        params={{
                            label: "Salutation",
                            type: "select",
                            name: "salutation",
                            placeholder: "Salutation",
                            ariadescribedby: "salutation-description",
                            description:"",
                            options: [{
                                "label":"",
                                value: "",
                                disabled: true
                            },{
                                label : "Mr.",
                                value : "mr."
                            },{
                                label : "Ms.",
                                value : "ms."
                            },{
                                label : "Mrs.",
                                value : "mrs."
                            },{
                                label : "Dr.",
                                value : "dr."
                            },{
                                label : "Prof.",
                                value : "prof."
                            },{
                                label : "Other",
                                value : "other"
                            }],
                        }} 
                        handleChange={handleChange}
                    />
                </div>     


                {input_fields_line_1.map((field, index) => {
                    if(field.value){
                        set_first_name_value({...default_vals,[field.name] :field.value});
                    }
                    return  <Input valid={validation_obj_state[field.name]} Key={"perso1"+index} params={{...field, 
                    value : default_vals[field.name]}} handleChange={handleChange}/>})
                }


            </div>
            
            <div className="md:flex">
                <DatePicker day={default_vals["dayDob"]} month={default_vals["monthDob"]} year={default_vals["yearDob"]} handleChange={handleDateSelect} submit_form_fieldname="Dob" className="flex-none w-14" params={{label:t("Date of Birth"),description:"",required:true}}/>
            </div>
            
            {!show_tribal_id_zone && (
            <div className="flex">
               
                <div className="flex-auto"> 
                    <Input valid={validation_obj_state["ssn4"]} handleChange={handleChange} className="flex-auto" params={{
                        label: "Last 4 Digits of Your Social Security Number",
                        type: "text",
                        name: "ssn4",
                        placeholder: "XXXX",
                        ariadescribedby: "ssn4-description",
                        description:"",
                        maxLength: 4,
                        minLength: 4,
                        required : true,
                        value: default_vals["ssn4"]
                    }}/>
                </div>
                <div className="flex-auto md:mt-10">
                    <Checkbox  handleChange={useTribalIdOrSSN} params={{
                                label : "Use Tribal ID instead of SSN", 
                                required : false, 
                                name: "use-tribal-id-instead-of-ssn",
                                type : "checkbox",
                                checked : default_vals["useTribalIdInsteadOfSSN"],
                                ariadescribedby: "use-another-shipping-address",
                    }}/>
                </div>
            </div>
            )}

            {show_tribal_id_zone && (<div className="flex">
                <div className="flex-auto">
                    <Input valid={validation_obj_state["tribalId"]} handleChange={handleChange} params={{
                            label: "Tribal ID",
                            type: "text",
                            name: "tribalId",
                            placeholder: "XXXXXXXXXXXXXXXX",
                            ariadescribedby: "tribal_id",
                            description:"",
                            maxLength: 20,
                            minLength: 2,
                            required : false,
                            value: default_vals["tribalId"]
                        }}/>
                </div>
                <div className="flex-auto md:mt-10">
                    <Checkbox  handleChange={useTribalIdOrSSN} params={{
                                label : "Use SSN instead of Tribal ID", 
                                required : false, 
                                name: "use-ssn-id-instead-of-tribal-id",
                                type : "checkbox",
                                checked : default_vals["useTribalIdInsteadOfSSN"],
                                ariadescribedby: "use-another-shipping-address",
                    }}/>
                </div>
                </div>
            )}

            <div className="md:flex">
                <Input selected={default_vals['wayToReach']}  handleChange={handleChangeRadioWay}  className="flex-auto" params={{
                                label : "What is the best way to reach you?", 
                                required : true, 
                                type : "radio", 
                                options : [
                                    {name: "wayToReach", title : "Phone", value : "phone"},
                                    {name: "wayToReach", title : "Email", value : "email"},
                                    {name: "wayToReach", title : "Text Message", value : "text-msg"},
                                    {name: "wayToReach", title : "Mail", value : "mail"},
                                ]
                            }}/>

                
            </div>
            <div className="md:flex">
                <Input valid={validation_obj_state["contactPhoneNumber"]} handleChange={handleChange}  className="flex-auto" params={{
                    label: "Contact Phone Number",
                    type: "tel",
                    name: "contactPhoneNumber",
                    placeholder: "XXXXXXXXXX",
                    ariadescribedby: "contact-phone-description",
                    description:"",
                    required : phone_number_required,
                    value: default_vals["contactPhoneNumber"]
                }}/>
                <Input valid={validation_obj_state["contactEmail"]}  handleChange={handleChange}  className="flex-auto" params={{
                    label: "Contact Email Address",
                    type: "email",
                    name: "contactEmail",
                    placeholder: "Contact email address",
                    ariadescribedby: "contact-email-description",
                    description:"",
                    required : true,
                    value: default_vals["contactEmail"]
                }}/>
            </div>
            <div>
                <Input  handleChange={handleChange} className="flex-auto" params={{
                    label: "Subscriber Address",
                    type: "text",
                    name: "address",
                    placeholder: "Subscriber Address",
                    ariadescribedby: "residence-address-description",
                    description:"No P.O. Box address",
                    required : true,
                    value: default_vals["address"]
                }}/>
            </div>
            <div className="md:flex"> 
                

                <Input  
                    valid={validation_obj_state["city"]} 
                    params={{
                        label: "City",
                        type: "text",
                        name: "city",
                        placeholder: "City",
                        ariadescribedby: "city-description",
                        description:"",
                        value: default_vals["city"],
                        required : true
                    }} 
                    handleChange={handleChange}
                />

                <Input  
                    
                    selected={default_vals["state"]}
                    params={{
                        label: "State",
                        type: "select",
                        name: "state",
                        placeholder: "State",
                        ariadescribedby: "state-description",
                        description:"",
                        options: usa_states.map((state) => {
                            return {
                                    label:state.name,
                                    value:state.abbreviation
                            };
                        }),
                        required : true
                    }} 
                    handleChange={handleChange}
                />


                {show_zip_code && <Input  valid={validation_obj_state["zipCode"]} handleChange={handleChange} className="flex-auto" params={{
                    label: "Zip Code",
                    type: "text",
                    name: "zipCode",
                    placeholder: "Zip Code",
                    ariadescribedby: "zip-code-description",
                    description:"",
                    display: show_zip_code,
                    value:default_vals['zipCode'],
                    required : true
                }}/>
                }
                {show_urbanization_code && <Input  handleChange={handleChange} className="flex-auto" params={{
                    label: "Urbanization Code",
                    type: "text",
                    name: "urbanizationCode",
                    placeholder: "Urbanization Code",
                    ariadescribedby: "zip-code-description",
                    description:"",
                    display: show_urbanization_code,
                    value:default_vals['urbanizationCode'],
                    required : true
                }}/>
                }
            </div>
            <div>
                <Input  selected={default_vals['residenceType']}  handleChange={handleChangeRadio} params={{
                                label : "My residential address is :", 
                                required : true, 
                                type : "radio", 
                                options : [
                                    {name: "residenceType", title : "Temporary", value : "temporary"},
                                    {name: "residenceType", title : "Permanent", value : "permanent"}
                                ]
                            }}/>
            </div>
                            
            
            <div className="mt-10 mb-2">
                <Checkbox  handleChange={showZoneShippingAddr} params={{
                                label : "Add Shipping Address if different from the subscriber address", 
                                required : false, 
                                name: "useAnotherShippingAddress", //"use-another-shipping-address", //
                                type : "checkbox",
                                checked : default_vals["useAnotherShippingAddress"],
                                ariadescribedby: "use-another-shipping-address",
                            }}/>
            </div> 
            
            { default_vals["useAnotherShippingAddress"] && <div className="bg-gray-100 rounded-md p-2">
                {/* Use another shipping address */}
                
                <div>
                    <Input  handleChange={handleChange} className="flex-auto" params={{
                        label: "Shipping Address",
                        type: "text",
                        name: "otherAddress",
                        placeholder: "Shipping Address",
                        ariadescribedby: "shipping-address-description",
                        description:"",
                        value: default_vals["otherAddress"],
                        required : true
                    }}/>
                </div>
                <div className="md:flex">
                    {input_fields_line_4.map((field, index) => {
                        if(field.value){
                            set_first_name_value({...default_vals,[field.name] :field.value});
                        }
                        return  <Input Key={"perso1"+index} params={{...field, 
                        value : default_vals[field.name]}} handleChange={handleChange}/>})
                    }
                    
                    {show_ship_zip_code && <Input  handleChange={handleChange} className="flex-auto" params={{
                        label: "Shipping Zip Code",
                        type: "text",
                        name: "otherZipCode",
                        placeholder: "Shipping Zip Code",
                        ariadescribedby: "zip-code-description",
                        description:"",
                        value: default_vals["otherZipCode"],
                        display: show_ship_zip_code,
                        required : true
                    }}/>
                    }
                    {show_ship_urbanization_code && <Input  handleChange={handleChange} className="flex-auto" params={{
                        label: "Shipping urbanization Code",
                        type: "text",
                        name: "otherUrbanizationCode",
                        placeholder: "Shipping Urbanization Code",
                        ariadescribedby: "shipping-zip-code-description",
                        description:"",
                        value: default_vals["otherUrbanizationCode"],
                        display: show_ship_urbanization_code,
                        required : true
                    }}/>
                    }
                </div>


            </div>
            }


            <div className="mt-10 mb-2">
                <Checkbox  handleChange={handleClickCheckBox} params={{
                                label : "Qualifying through a child or dependent in your household other than yourself?", 
                                name: "applyForSomeoneElse",
                                required : false, 
                                type : "checkbox",
                                checked : default_vals["applyForSomeoneElse"],
                                ariadescribedby: "apply-for-someone-else",
                            }}/>
            </div>

            { default_vals["applyForSomeoneElse"] && <div className="bg-gray-100 rounded-md p-2">
                
                <div className="md:flex">
                    {input_fields_line_3.map((field, index) => {
                        if(field.value){
                            set_first_name_value({...default_vals,[field.name] :field.value});
                        }
                        return  <Input valid={validation_obj_state[field.name]} Key={"perso1"+index} params={{...field, 
                        value : default_vals[field.name]}} handleChange={handleChange}/>})
                    }
                </div>
                <div className="md:flex">
                    <DatePicker day={default_vals["dayBqpDob"]} month={default_vals["monthBqpDob"]} year={default_vals["yearBqpDob"]} bqp={true} handleChange={handleDateSelect} submit_form_fieldname="BqpDob" className="flex-none w-14" params={{label:t("Beneficiary Date of Birth"),description:"",required:true}}/>
                    
                </div>

                {!show_tribal_id_zone_bqp && (
            <div className="flex">
               
                <div className="flex-auto"> 
                    <Input valid={validation_obj_state["ssn4"]} handleChange={handleChange} className="flex-auto" params={{
                        label: "Last 4 Digits of your Social Security Number",
                        type: "text",
                        name: "bqpSsn4",
                        placeholder: "XXXX",
                        ariadescribedby: "ssn4-description",
                        description:"",
                        maxLength: 4,
                        minLength: 4,
                        required : true,
                        value: default_vals["bqpSsn4"]
                    }}/>
                </div>
                <div className="flex-auto md:mt-10">
                    <Checkbox  handleChange={useTribalIdOrSSN_bqp} params={{
                                label : "Use Tribal ID instead of SSN", 
                                required : false, 
                                name: "use-tribal-id-instead-of-ssn-bqp",
                                type : "checkbox",
                                checked : default_vals["useTribalIdInsteadOfSSN"],
                                ariadescribedby: "use-another-shipping-address",
                    }}/>
                </div>
            </div>
            )}

            {show_tribal_id_zone_bqp && (<div className="flex">
                <div className="flex-auto">
                    <Input valid={validation_obj_state["bqpTribalId"]} handleChange={handleChange} params={{
                            label: "Tribal ID",
                            type: "text",
                            name: "bqpTribalId",
                            placeholder: "XXXXXXXXXXXXXXXX",
                            ariadescribedby: "tribal_id",
                            description:"",
                            maxLength: 20,
                            minLength: 2,
                            required : false,
                            value: default_vals["bqpTribalId"]
                        }}/>
                </div>
                <div className="flex-auto md:mt-10">
                    <Checkbox  handleChange={useTribalIdOrSSN_bqp} params={{
                                label : "Use SSN instead of Tribal ID", 
                                required : false, 
                                name: "use-ssn-id-instead-of-tribal-id-bqp",
                                type : "checkbox",
                                checked : default_vals["useTribalIdInsteadOfSSN"],
                                ariadescribedby: "use-another-shipping-address",
                    }}/>
                </div>
                </div>
            )}
            </div>
            }

            

            <div>
                {/* Applying for somebody else? */}

            </div>

            

            <div style={{display:show_error_msg ? "" : "none"}} className="mt-10 px-10 py-3 text-slate-700 rounded-md bg-red-100">
                <div>
                    This is an error zone
                </div>
                <p>
                    <small>Please</small> 
                </p>
            </div>

            <DialogMessage 
                open={open_dialog} 
                type="confirm"
                title="Are you sure you want to restart application?" 
                description="You will lose all the data you have entered so far." 
                show_close_dialog={handleCloseDialog}
                handleConfirm={handleRestartApplication}
                buttons={[
                    {label:"Cancel"},
                    {label:"Restart Application"}
                ]}
            />


            <div className="mt-10 mb-2">
                <div className="my-2 font-l">Please read the terms and conditions</div>
                <fieldset className="space-y-5">
                    <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                        <input
                           
                            aria-describedby="terms-and-conditions"
                            name="consentInd"
                            type="checkbox"
                            required="true"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        </div>
                        <div className="ml-3 text-xs">
                            <label htmlFor="terms-and-conditions" className="font-medium text-gray-700">
                                <div> 
                                    <p className="mb-2">I ,or my dependent or other person in my household) currently get benefits from the government program(s) listed on this form or my annual household income is 200% or less than the Federal Poverty Guidelines (the amount listed in the Federal Poverty Guidelines table on this form).</p>
                                    <p className="mb-2">I agree that if I move I will give my service provider my new address within 30 days.</p>
                                    <p className="mb-2">I understand that I have to tell my service provider within 30 days if I do not qualify for the ACP anymore, including:
                                        <ul>
                                            <ol>I, or the person in my household that qualifies, do not qualify through a government program or income anymore.</ol>
                                            <ol>Either I or someone in my household gets more than one ACP benefit.</ol>
                                        </ul>
                                    </p>
                                    <p className="mb-2">
                                        I know that my household can only get one ACP benefit and, to the best of my knowledge, my household is not getting more than one ACP benefit. I understand that I can only receive one connected device (desktop, laptop, or tablet) through the ACP, even if I switch ACP providers.
                                    </p>
                                    <p className="mb-2">
                                        I agree that all of the information I provide on this form may be collected, used, shared, and retained for the purposes of applying for and/or receiving the ACP benefit. I understand that if this information is not provided to the Program Administrator, I will not be able to get ACP benefits. If the laws of my state or Tribal government require it, I agree that the state or Tribal government may share information about my benefits for a qualifying program with the ACP Administrator. The information shared by the state or Tribal government will be used only to help find out if I can get an ACP benefit.
                                    </p>
                                    <p className="mb-2">
                                        For my household, I affirm and understand that the ACP is a federal government subsidy that reduces my broadband internet access service bill and at the conclusion of the program, my household will be subject to the provider’s undiscounted general rates, terms, and conditions if my household continues to subscribe to the service.

                                    </p>
                                    <p className="mb-2">
                                        All the answers and agreements that I provided on this form are true and correct to the best of my knowledge.
                                    </p>
                                    <p className="mb-2">
                                        I know that willingly giving false or fraudulent information to get ACP benefits is punishable by law and can result in fines, jail time, de-enrollment, or being barred from the program.
                                    </p>
                                    <p>
                                        <div><i><a href="https://rural4g.com/privacy/">Rural 4G and your data</a></i></div>
                                        This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
                                        We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this ... <a href="https://rural4g.com/privacy/" className="font-bold">Read more</a>
                                    </p>
                                </div>
                            </label>
                        </div>
                    </div>
                    
                </fieldset>
                
                
                        
            </div>

            <div className="mx-2 mt-8">
                {warning_message && <WarningAlert message={warning_message}/>}
            </div>
            
            <div className="mt-10">
                    <button type="button" onClick={handleClickRestartApplication} className="border rounded-md bg-red-500 text-white p-2 px-4">
                        Restart application
                    </button>
                    <Link to="/coverage">
                        <button type="button" className="border rounded-md bg-slate-800 text-white p-2 px-4">
                            Previous
                        </button>
                    </Link>
                    <button type="submit" className="border rounded-md bg-rural-4g-2 text-white p-2 px-4">
                        Next
                    </button>
            </div>
        </form>
    );
}