import { useState } from "react";
import { Dot } from "react-animated-dots";
import { saveToMailchimp } from "../../../net/api";
import { usa_states } from "../../eligibility/components/usa_states";

export default function ContactForm(props) {
  const [form, set_form] = useState({
    FIRST_NAME: "",
    LAST_NAME: "",
    EMAIL: "",
    ADDRESS: "",
    STATE: "",
    ZIPCODE: props.zip_code ? props.zip_code : "",
    PHONE: "",
    TIMEZONE: "",
    CONTACT: "",
    NOTES: "",
    CAUSE : props.cause ? props.cause : "",
  });

  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    console.log(form);
    set_form({
      ...form,
      [e.target.name]: e.target.value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(form);


    if(form["ZIPCODE"].length !== 5 || !/^\d+$/.test(form["ZIPCODE"])) {
      alert('Please enter a valid 5 digit zip code.');
      return;
    }

    //find field that is empty in form
    const empty_field = Object.keys(form).find(key => form[key] === "");
    if (empty_field && empty_field !== "NOTES") {
        console.log(empty_field);
        document.getElementsByName(empty_field)[0] && document.getElementsByName(empty_field)[0].focus();
        return;
    }
    try{
      setSubmitting(true);
      const res = await saveToMailchimp(form);

      if(res && res.success === true) {
        window.location.href = process.env.REACT_APP_SUCCESS_URL_MAILCHIMP;
      }else{
        alert("An error occured, please try again later."); 
      }

    }catch(ex){
      setSubmitting(false);
    }
  }

  return (
    <>
      <div className="md:col-span-1 mt-4 mb-4">
        <h3 className="text-lg font-medium leading-6 text-gray-900">An agent will get in touch with you</h3>
        {!(props && props.hide_description) && <p className="mt-1 text-sm text-gray-500">
          As we could not find coverage in your area, an agent will contact you to help you with your request.
        </p>}
      </div>

      <div className="mt-5 space-y-6 md:col-span-2 md:mt-0 ">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-3 gap-6 ">
            <div className="col-span-6 sm:col-span-4 ">
              <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                First name <span className="text-red-600">*</span>
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="FIRST_NAME"
                  onChange={handleChange}
                  className="block w-full flex-1 rounded-md shadow border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter your first name"
                />
              </div>
            </div>

            <div className="col-span-6 sm:col-span-4 ">
              <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                Last name <span className="text-red-600">*</span>
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="LAST_NAME"
                  onChange={handleChange}
                  className="block w-full flex-1 rounded-md shadow border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter your last name"
                />
              </div>
            </div>

            <div className="col-span-6 sm:col-span-4 ">
              <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                Email address <span className="text-red-600">*</span>
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="email"
                  name="EMAIL"
                  onChange={handleChange}
                  className="block w-full flex-1 rounded-md shadow border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter your email address"
                />
              </div>
            </div>

            <div className="col-span-6 sm:col-span-4 ">
              <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                Phone number <span className="text-red-600">*</span>
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="tel"
                  name="PHONE"
                  onChange={handleChange}
                  className="block w-full flex-1 rounded-md shadow border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter your phone number"
                />
              </div>
            </div>

            {props.show_zip && <div className="col-span-6 sm:col-span-4 ">
              <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                ZIP Code <span className="text-red-600">*</span>
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="ZIPCODE"
                  minLength={5}
                  maxLength={5}
                  value={form.ZIPCODE}
                  onChange={handleChange}
                  className="block w-full flex-1 rounded-md shadow border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter your zip code"
                />
              </div>
            </div>}

            <div className="col-span-6 sm:col-span-4 ">
              <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                Your timezone <span className="text-red-600">*</span>
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <select
                  name="TIMEZONE"
                  onChange={handleChange}
                  className="block w-full flex-1 rounded-md shadow border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                  <option></option>
                  <option value="Eastern">Eastern</option>
                  <option value="Central">Central</option>
                  <option value="Mountain">Mountain</option>
                  <option value="Pacific">Pacific</option>
                  <option value="Alaska">Alaska</option>
                  <option value="Hawaii">Hawaii</option>
                </select>
              </div>
            </div>

            <div className="col-span-6 sm:col-span-4 ">
              <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                Home address <span className="text-red-600">*</span>
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="ADDRESS"
                  onChange={handleChange}
                  className="block w-full flex-1 rounded-md shadow border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter your home address"
                />
              </div>
            </div>

            <div className="col-span-6 sm:col-span-4 ">
              <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                State <span className="text-red-600">*</span>
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <select
                  name="STATE"
                  onChange={handleChange}
                  className="block w-full flex-1 rounded-md shadow border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                  {
                    usa_states.map((state, index) => {
                      return <option key={index} value={state.name}>{state.name}</option>
                    })
                  }
                </select>
              </div>
            </div>

            <div className="col-span-6 sm:col-span-4 ">
              <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                Best time to contact you <span className="text-red-600">*</span>
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <select
                  name="CONTACT"
                  onChange={handleChange}
                  className="block w-full flex-1 rounded-md shadow border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option></option>
                  <option value="9am - 12pm ET">9am - 12pm ET</option>
                  <option value="1pm - 6pm ET">1pm - 6pm ET</option>
                  <option value="6pm - 9pm ET">6pm - 9pm ET</option>
                </select>
              </div>
            </div>

            <div className="col-span-6 sm:col-span-4 ">
              <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                Add a note 
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <textarea
                  type="time"
                  name="NOTES"
                  id="company-website"
                  onChange={handleChange}
                  className="block w-full flex-1 rounded-md shadow border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter a special note"
                ></textarea>
              </div>
            </div>

            <div className="col-span-6 sm:col-span-4 ">
              <button type="button" onClick={handleSubmit} className="px-4 py-2 bg-indigo-500 hover:bg-indigo-600 text-white rounded-md">
                <span className="text-white">{submitting ? <span>Submitting <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></span> : `Submit`} </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}