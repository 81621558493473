import { useState } from "react";
import { Dot } from "react-animated-dots";
import { checkZipCodeHasCoverage } from "../../../net/api";
import ContactForm from "./ContactForm";

export default function EnterZipCode(props) {
    const [loading, setLoading] = useState(false);
    const [found_zip, setFoundZip] = useState(null);

    const handleFormSubmit = (e) => {
      e.preventDefault();
      handleCheckNow();
    }

    const handleCheckNow = () => {

      const the_zip = props.state.zip_code;
      // Check if zip code is valid number
      if(the_zip.length !== 5 || !/^\d+$/.test(the_zip)) {
        alert('Please enter a valid 5 digit zip code.');
        return;
      }
      setLoading(true);
      checkZipCodeHasCoverage(props.state.coverage_for, props.state.zip_code).then(res => {
        setLoading(false);
        const found = res && res.zip_found;
        
        props.set_state_fn({
            ...props.state,
            zip_covered: found ? 'yes' : 'no',
            have_cellular_coverage : found ? 'idk' : 'idk',
            zip_can_be_covered : found ? true : false
        });
        setFoundZip(found);
      }).catch(err => {
        setLoading(false);
        console.log(err);
      });
    }

    const handleChange = (e) => {
        props.set_state_fn({
            ...props.state,
            zip_code: e.target.value
        });
    }

    return (
      <form className="space-y-6 mt-4" onSubmit={handleFormSubmit} method="POST">
        <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Zip Code</h3>
              <p className="mt-1 text-sm text-gray-500">
                Enter your zip code to check if we have coverage in your area.
              </p>
            </div>

            <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-3">
                  <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                    
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="zip_code"
                      id="zip_code"
                      minLength={5}
                      maxLength={5}
                      onChange={handleChange}
                      value={props.state.zip_code}
                      className="block w-full flex-1 rounded-md shadow border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="Enter your zip code here"
                    />
                    <button type="button" onClick={handleCheckNow} className="px-2 mx-2 bg-indigo-500 hover:bg-indigo-600 text-white rounded-md">
                        {!loading && 'Check now'} 
                        {loading && <>Checking <Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></>}
                    </button>
                  </div>
                  {props.state.zip_covered === 'no' && <div className="text-sm mt-2 text-red-400">Sorry, we could not determine if your ZIP Code is covered. <br/> Please fill out the form below to get contacted by our support agent.</div>}
                  {props.state.zip_covered === 'yes' && <div className="text-sm mt-2 text-green-600">Your ZIP Code is totally covered.</div>}
                </div> 
              </div>
            </div>

            {props.state.zip_covered === 'idk' ? '' : props.state.zip_covered === 'no' ? <ContactForm cause="Not sure we cover area." zip_code={props.state.zip_code}/> : ''}



          </div>
        </div>

      </form>
    )
  }
  