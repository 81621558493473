import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import CheckCoverage from "./pages/check-coverage/CheckCoverage";
import ConfirmElibility from "./pages/confirm/ConfirmEligibity";
import Elibility from "./pages/eligibility/Eligibility";
import FinalEligibility from "./pages/final/FinalEligibility";
import Conditions from "./pages/order/Conditions";
import Order from "./pages/order/Order";
import Plans from "./pages/order/Plans";
import SelectProgram from "./pages/selectProgram/SelectProgram";
import Start from "./pages/start/Start";
import { StatusCheck } from "./pages/status-check/StatusCheck";
import DataVerification from "./pages/verification/DataVerification";
import RegistrationCompleted from "./pages/registration_completed/RegistrationCompleted";
import Pause from "./pages/pause/Pause";


const MainRouter = () => {
    return (
        <div>
            <BrowserRouter>
                <Routes>

                    <Route path="/start-1" element={<Start />} />
                    <Route path="/" element={<Pause />} />
                    <Route path="/eligibility" element={<Elibility />} />
                    <Route path="/select-program" element={<SelectProgram/> }/>
                    <Route path="/verif" element={<DataVerification />} />
                    <Route path="/confirm" element={<ConfirmElibility />} />
                    <Route path="/final" element={<FinalEligibility />} />
                    <Route path="/status-check" element={<StatusCheck/>}/>
                    <Route path="/coverage" element={<CheckCoverage/>}/>
                    <Route path="/order" element={<Order/>}/>
                    <Route path="/order/conditions" element={<Conditions/>}/>
                    <Route path="/order/plans" element={<Plans/>}/>
                    <Route path="/completed/:verif_code" element={<RegistrationCompleted />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default MainRouter;