import ContactForm from "./ContactForm";

export default function NotAvailable() {
    return (
      <>
        <div className="min-h-full bg-cover bg-top sm:bg-top mt-8">
          <div className="mx-auto max-w-7xl text-center">
            <h1 className="mt-2 text-xl hidden font-bold tracking-tight  sm:text-xl">Sorry</h1>
            <p className="my-4 lg:w-1/2 lg:mx-auto text-lg font-medium text-black text-opacity-50">
              As you are not in a carrier coverage area at the moment, we may be unable to provide you with our services.
            </p>
            <div className="md:grid md:grid-cols-3 md:gap-6 text-left">
              <ContactForm hide_description={false} show_zip={true} cause="does not have network coverage"/>
            </div>
          </div>
        </div>
      </>
    )
  }
  