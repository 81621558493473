
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/24/solid'
import {Link} from "react-router-dom";

import r4glogo from '../../res/img/R4G-logo.png';
import '../../styles/main.css';

import StepsNavigation from '../../shared_components/StepsNavigation';
import Layout from '../../shared_components/Layout';

export default function FinalEligibility() {
  return (
    <Layout title="Final">
              <div className='p-10 bg-slate-50'>
                Final steps & more info - if needed.<br/>
                <div className='text-red-500'> This can be removed</div>
              </div>
            
              

    </Layout>
  )
}
